
<template>
  <div id="barcodeScan" class="barcodeScan"
      style="position:absolute; width:600px; height:100px; top:15%; left:15%;"
  >
    <StreamBarcodeReader
      @decode="onDecode"
      @error="onError"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
// import { ImageBarcodeReader } from "vue-barcode-reader";

let options = {
  sound: true, // default is false
  soundSrc: '/static/sound.wav', // default is blank
  sensitivity: 300, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: true // default is false
}
 
export default({
    name:'BarcodeReader',
    components: {
      StreamBarcodeReader:StreamBarcodeReader,
      // ImageBarcodeReader:ImageBarcodeReader
    },

    methods: {
      onDecode (result) {
        console.log('==== on decode: ', result)
      },
      onError (result) {
        console.log('==== on error: ', result)
      }
    }
})
</script>
