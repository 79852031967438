<template>
    <div>
      <v-data-table
      v-model="selectedRecords"
        :headers="headers"
        :items="items"
        item-key="id"
        :mobile-breakpoint="1024"
        class="elevation-1"
        
        hide-default-footer
        hide-default-header
        no-data-text=""
        style="width:100%; height:78%; overflow:scroll;"
        disable-pagination
      >
        <template v-slot:item.actions="{ item }">
            <v-toolbar
            light
            color='#cddaed'
          >
            <v-checkbox
              v-model="item.checkbox"
              :disabled=true
            ></v-checkbox>
            <v-btn 
              icon
              x-large
              @click="copyRow(item)"
            >
              <v-icon>
                mdi-plus-circle
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn 
              icon
              x-large
              @click="removeRow(item.id)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-toolbar>
            <v-card>
                <v-card-title :class="selectedRecords.indexOf(item.id)>-1?'card-text-item-code-click':'card-text-item-code'" >
                    {{ item.id }}                    
                </v-card-title>
                <v-card-subtitle :class="selectedRecords.indexOf(item.id)>-1?'card-text-item-name-click':'card-text-item-name'" >
                    {{ item.name }}<br/>
                    {{ item.number }}<br/>
                    {{ item.actions }}
                </v-card-subtitle>
                <v-card-text style="font-size:20px; font-weight:bold;" class="d-flex justify-start row-2" >
                    <v-spacer></v-spacer> 
                    <v-btn color="primary" 
                        @click="openDialog(item)"
                        @click.stop="v-btn"
                    >
                        {{ item.buttonText }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </template>
      </v-data-table>
  
      <v-dialog v-model="dialogVisible" max-width="500px">
        <v-card>
          <v-card-title>
            Edit Value
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="dialogValue" label="Value"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="confirmDialog">Confirm</v-btn>
            <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Number', value: 'number' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        items: [],
        dialogVisible: false,
        dialogValue: '',
        selectedItem: null,
        selectedRecords:[],
      };
    },
    methods: {
      openDialog(item) {
        this.selectedItem = item;
        this.dialogValue = item.buttonText;
        this.dialogVisible = true;
      },
      confirmDialog() {
        this.selectedItem.buttonText = this.dialogValue;
        this.closeDialog();
      },
      closeDialog() {
        this.dialogVisible = false;
      }
    },
    mounted() {
      // Generate initial random values for the button text
      for (let i = 1; i <= 5; i++) {
        this.items.push({
          id: i,
          name: `Item ${i}`,
          number: Math.floor(Math.random() * 100),
          buttonText: Math.floor(Math.random() * 100).toString()
        });
      }
    }
  };
  </script>
  <style >
  .card-text-item-code  {
    font-size: 1.3em;
    font-weight: bold;
    color:#264b8c !important;
  }
  .card-text-item-code-click {
    font-size: 1.3em;
    font-weight: bold;
    color:#ffffff !important;
  }
  .card-text-item-name  {
    font-size: 1.2em;
    font-weight: 400;
    color:#264b8c !important;
  }
  .card-text-item-name-click {
    font-size: 1.2em;
    font-weight: 400;
    color:#ffffff !important;
  }
  .card-text-specification  {
    color:#264b8c !important;
  }
  .card-text-specification-click {
    color:#ffffff !important;
  }
</style>