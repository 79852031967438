<template>
<div>
    <v-btn
        @click="startScan"
    >Click</v-btn>
    <div id="barcode_scan"></div>
</div>
    
</template>

<script src="https://unpkg.com/html5-qrcode" type="text/javascript" />
<script>
    import {Html5QrcodeScanner} from "html5-qrcode"

    export default {
        data() {
            return {
                scanner:null,
                qrbox:null,
                fps:null,
            }
        },
        // mounted: function () {
        //     var config = { fps: this.fps ? this.fps : 10 };
        //     if (this.qrbox) {
        //         config['qrbox'] = this.qrbox;
        //     }

        //     function onScanSuccess(qrCodeMessage) {
        //         console.log(qrCodeMessage);
        //         alert(qrCodeMessage)
        //     }
            
        //     var html5QrcodeScanner = new Html5QrcodeScanner(
        //         "qr-code-full-region", config);
        //     html5QrcodeScanner.render(onScanSuccess, this.onScanError);
        // },
        components: {
            
        },
        methods: {
            startScan() {
                console.log('=== startScan')
                // var config = { fps: this.fps ? this.fps : 10 };
                // if (this.qrbox) {
                //     config['qrbox'] = this.qrbox;
                // }

                var config = {fps:this.fps, qrbox:this.qrbox}

                this.scanner = new Html5QrcodeScanner(
                "barcode_scan", { fps: 10, qrbox: {width: 250, height: 50} });

                this.scanner.render(this.onScanSuccess, this.onScanError);
            },
            onScanSuccess(qrCodeMessage) {
                // handle on success condition with the decoded message
                console.log(qrCodeMessage);
                alert('onScanSuccess: ' + qrCodeMessage)
                this.scanner.clear();
                // ^ this will stop the scanner (video feed) and clear the scan area.
            },
            onScanError(errorMessage) {
                console.log(errorMessage);
                // alert('onScanError: ' + errorMessage)
                // handle on error condition, with error message
            }
        }
    }
</script>