<template>
  <div class="content" style="height:100%; min-height:100%; position:fixed;">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="padding:5%; overflow-y: scroll; height: 70%;"
    >
      <p style="text-align:center;">아래 정보를 입력해주세요</p>

      <v-text-field
        v-model="item_code"
        label="*품번"
        outlined
        clearable
        required
        @change="IsDuplicateUpdate"
      ></v-text-field>

      <v-text-field
        v-model="item_name"
        label="*품명"
        outlined
        clearable
        required
      ></v-text-field>

      <v-text-field
        v-model="specification"
        label="규격"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="detail_info"
        label="상세사양"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="unit_code"
        label="단위"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="safe_quan"
        label="안전재고수량"
        outlined
        clearable
      ></v-text-field>
    </v-form>

    <div style="text-align:right;">
      <v-btn
        text
        style="background-color:green;"
        :color="'white'"
        @click="checkDuplicate"
      >
        중복확인
      </v-btn>
    </div>
    
    <div style="text-align:center; margin-top:10%;">
      <v-btn
        text
        @click="registEvent"
        class="mr-1"
        style="width:100%; background-color: rgb(103, 173, 219); color:white;"
      >
        등록하기
      </v-btn>
    </div>
    
    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-dialog
    v-model="isLoading"
    persistent
    width="300"
  >
  <template>
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        품목을 등록중입니다.<br/>잠시만 기다려주세요.
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </template>
  </v-dialog>

  </div>
</template>
 
<script>
  import axios from 'axios'

  export default {
    mounted() {
      
    },
    components: {
      // StreamBarcodeReader:StreamBarcodeReader,
    },

    watch: {
      
    },

    data () {
      return {
        valid: true,

        item_code:null,
        item_name:null,
        specification:null,
        detail_info:null,
        unit_code:null,
        safe_quan:null,

        is_duplicate:false,

        isLoading:false,

        dialogAlaram:false,
        alaramText:''
      }
    },

    methods: {

      pageBack() {
        this.$router.push('home')
      },

      IsDuplicateUpdate() {
        this.is_duplicate = false
      },
      
      checkDuplicate() {
        if(this.item_code == null || this.item_code.length < 1) {
          this.dialogAlaram = true
          this.alaramText = '품번을 확인해주세요'
          return
        }

        var url = this.$vBACKEND_URL + '/item.do?method=checkDuplicateItemCode'
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          item_code:this.item_code
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            var result = res.result
            if(result) {
              this.dialogAlaram = true
              this.alaramText = '사용 가능한 품번입니다.'
              this.is_duplicate = true
            } else {
              this.dialogAlaram = true
              this.alaramText = '이미 사용중인 품번입니다.'
              this.is_duplicate = false
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally()
      },

      registEvent() {
        if(!this.is_duplicate) {
          this.dialogAlaram = true
          this.alaramText = '중복확인을 해주세요'
          return
        }

        if(this.item_code==null || this.item_code.length<1) {
          this.dialogAlaram = true
          this.alaramText = '품번을 확인 해주세요'
          return
        }

        if(this.item_name==null || this.item_name.length<1) {
          this.dialogAlaram = true
          this.alaramText = '품명을 확인 해주세요'
          return
        }

        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=registItem'
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          
          item_code:this.item_code,
          item_name:this.item_name,
          specification:this.specification,
          detail_info:this.detail_info,
          unit_code:this.unit_code,
          safe_quan:this.safe_quan,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            var result = res.result
            if(result) {
              this.dialogAlaram = true
              this.alaramText = '등록성공'

              this.refreshData();
            } else {
              this.dialogAlaram = true
              this.alaramText = '등록실패'
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      refreshData() {
        this.item_code = null
        this.item_name = null
        this.specification = null
        this.detail_info = null
        this.unit_code = null
        this.safe_quan = null
        this.is_duplicate = false
      },

      download_event() {
        console.log('download_event')

        var url = this.$vBACKEND_URL + '/item.do?method=itemExcelDownLoad';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const excelPath = res.result;
            location.href = this.$vCONTENT_URL + "/excel/" + excelPath
          })
          .catch(err => {
            console.log(err)
          })
      },

      upload_event() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/item.do?method=itemExcelUpload'
        var me = this

        // Event
        input.onchange = function() {
          const formData = new FormData()
          formData.append('file', this.files[0])
          formData.append('uid_company', me.$store.getters.getUidCompany)
          formData.append('user_uid', me.$store.getters.getUserUid)
          formData.append('user_id', me.$store.getters.getUserId)
          formData.append('user_name', me.$store.getters.getUserName)

          axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
        }
      },

      barcode_down_event() {
        console.log('barcode_down_event')
      },

    }
  }
</script>