<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="selectedWh"
            :items="wh_datas"
            menu-props="auto"
            outlined
            hide-details
            label="창고목록"
            item-text="search_wh_code"
            item-value="unique_id"
            @change="click"
            @click="inputClick"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>

      <v-data-table
        v-model="selectedItem"
        :headers="columns"
        :mobile-breakpoint="1024"
        :items="datas"
        hide-default-footer
        hide-default-header
        no-data-text=""
        class="elevation-1"
        style="width:100%; height:90%; overflow:scroll;"
        disable-pagination
      >
        <template v-slot:item="row" >
          <v-card
            class="mx-auto"
            :color="row.item.unique_id<=1?'green':'#264b8c'"
            width="100%"
            style="margin-bottom:10px;"
            :key="row.item.unique_id"
          >
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;{{row.item.rack_code}}
              <v-spacer></v-spacer> 
              &nbsp;{{row.item.rack_rate}}
            </v-card-text>
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;총BIN수
              <v-spacer></v-spacer> 
              &nbsp;{{row.item.total_bin}}
            </v-card-text>
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;사용BIN수
              <v-spacer></v-spacer> 
              &nbsp;{{row.item.use_bin}}
            </v-card-text>
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;잔여BIN수
              <v-spacer></v-spacer> 
              &nbsp;{{row.item.total_bin - row.item.use_bin}}
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
  </div>
</template>
 
<script>

  export default {
    mounted() {
      
    },
    components: {
     
    },

    watch: {
      searchModel(val) {
        if(val!=null) {
          this.selectedBinCode = val.unique_id
        } else {
          this.selectedBinCode = null
        }
      },

      search(val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    data () {
      return {
        selectedWh:null,
        wh_datas:[],

        search:null,
        searchModel:null,
        searchItems:[],
        isLoading:false,
        selectedBinCode:null,

        dialogAlaram:false,

        columns:[],
        datas:[],
        selectedItem:[],

        dates:[],

        start_menu:false,
        end_menu:false,

        start_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        alaramText:'',
      }
    },

    methods: {
      inputClick() {
        // 데이터  호출
        var url = this.$vBACKEND_URL + '/stock.do?method=readwhouse';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.wh_datas = res.datas
          })
          .catch(err => {
            console.log(err)
          })
      },
      click(uid_whouse) {
        var url = this.$vBACKEND_URL + '/stock.do?method=readWhouseRackRate';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        if(uid_whouse==null || uid_whouse<1) {
          this.dialogAlaram = true;
          this.alaramText = '위치를 검색해주세요';
          return;
        }

        obj['uid_whouse'] = uid_whouse
        // obj['uid_bin'] = this.selectedBinCode

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const datas = res.datas;
            console.log('@@@@@ datas', datas)
            this.datas = datas;
          })
      },

      getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBinRate';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        // search item condition
        if(this.selectedBinCode==null || this.selectedBinCode < 1) {
          this.dialogAlaram = true;
          this.alaramText = '위치를 검색해주세요';
          return;
        }

        obj['uid_bin'] = this.selectedBinCode

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const datas = res.datas;
            this.datas = datas;
          })
      },

      clear() {
        this.datas = []
      }

    }
  }
</script>