// 라우트 객체에 사용할 링크
import VueRouter from'vue-router'

// 메뉴 Store
const store = [
  { code:'HOME', name:'HOME', link: 'components/views/Home' },
  { code:'LOGIN', name:'로그인', link: 'components/main/LoginForm' },
  { code:'WEARING', name:'입고', link: 'components/views/Wearing' },
  { code:'RELEASE', name:'출고', link: 'components/views/Release' },
  { code:'STOCKMOVE', name:'재고위치조정', link: 'components/views/StockMove' },
  { code:'STOCKSEARCH', name:'재고검색', link: 'components/views/StockSearch' },
  { code:'BARCODE', name:'바코드', link: 'components/utils/BarcodeReader' },
  { code:'LOCATION', name:'위치', link: 'components/views/LocationView' },
  { code:'SEARCH_ITEM', name:'품목별 재고조회', link: 'components/views/SearchItem' },
  { code:'SEARCH_LOCATION', name:'위치별 조회', link: 'components/views/SearchLocation' },
  { code:'SEARCH_LOADRATE', name:'적재율', link: 'components/views/SearchLoadRate' },
  { code:'STOCK_MGMT', name:'재고현황', link: 'components/views/StockMgmtView' },

  { code:'SELECT_ITEM_VIEW', name:'품목관리', link: 'components/views/SelectItemView' },
    { code:'REGIST_ITEM', name:'등록', link: 'components/views/RegistItemView' },
    { code:'MGMT_ITEM', name:'관리', link: 'components/views/MgmtItemView' },
  { code:'REGIST_LOCATION_BARCODE', name:'위치등록', link: 'components/views/RegistLocationBarcode' },
  { code:'REGIST_LOCATION_MANUAL', name:'위치등록 수동', link: 'components/views/RegistLocationManual' },

  { code:'BARCODE_SCAN', name:'바코드 스캔', link: 'components/views/QuaggaJs' },
  // { code:'SCAN', name:'바코드', link: 'components/utils/ScanView' }
  //{ code:'test2', name:'test', link: 'components/views/Test2' }
]

const createRoutes = () => {
  let routes = [];
  if(store!=null && store.length>0) {
    for(var i in store) {
      var s = store[i];

      var code = s.code;
      var name = s.name;
      var link = s.link;

      var obj = {
        path: '/' + code.toLowerCase(),
        name: code,
        component: loadView(link),
        // props: (route) => ({ query:store }),
        props: true,
        meta: { title:name }
      }

      routes.push(obj);
    }
  }

  var scan_route = {
    path:'/scan',
    name:'SCAN',
    props: true,
    component: () => import('@/components/utils/ScanView.vue')
  }
  routes.push(scan_route)

  // 항상 마지막에 입력.
  var def_route = {
    path:'/:catchAll(.*)',
    component: () => import('@/components/main/NotFoundPage.vue')
  }
  routes.push(def_route)

  return routes;
}

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${view}.vue`)
}

const router = new VueRouter({
  mode: "history",
  routes: createRoutes()
});

export default router;