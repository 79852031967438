<template>
  <div class="content" style="height:100%; min-height:100%; position:fixed;">
    <v-container style="height:90%; margin-top: 10%;">
      <v-row style="height:40%; justify-content: center;">
        <v-col cols="8" sm="8" md="8" style="height:100%;">
          <v-card style="height:100%;" @click="goToMenu('REGIST_ITEM')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:2em;">
              등록
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/mgmt_item.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="height:40%; justify-content: center;">
        <v-col cols="8" sm="8" md="8" style="height:100%;">
          <v-card style="height:100%;" @click="goToMenu('MGMT_ITEM')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:2em;">
              관리
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/search_item.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
 
<script>
  export default {
    mounted() {
      
    },
    components: {
      
    },

    watch: {
      
    },

    data () {
      return {
        
      }
    },

    methods: {
      goToMenu(path) {
        this.$router.push(path);
      },

      pageBack() {
        this.$router.push('home')
      },

    }
  }
</script>