<!-- LocationView.vue -->
 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            filled
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"
            
            clearable
            hide-details
            item-text="bin_code"
            item-value="unique_id"
            return-object
            label="위치검색"
            solo
            @click:append-outer="openScanner($store.getters.getCodeType)"
            @change="click"
            @click:clear="clear"
          >
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left>
                  mdi-format-list-bulleted-square
                </v-icon>
                <span v-text="item.bin_code"></span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
      
    <v-data-table
      v-model="selectedBin"
      :headers="columns"
      :mobile-breakpoint="1024"
      :items="datas"
      hide-default-footer
      hide-default-header
      class="elevation-1"
      style="width:100%; height:78%; overflow:scroll;"
    >
      <template v-slot:item="row" >
        <v-toolbar
          light
          color='#65D285'
        >
          <v-checkbox
            v-model="row.item.checkbox"
            :disabled=true
          ></v-checkbox>
          <v-spacer></v-spacer>
          <!-- <v-btn 
            icon
            x-large
            @click="removeRow(row.item.id)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn> -->
        </v-toolbar>
        <v-card
          class="mx-auto"
          :color="selectedBin.indexOf(row.item.unique_id)>-1?'cyan':'green'" 
          @click="rowClicked(row.item)"
          dark
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.unique_id"
        >
          
          <v-card-text style="font-size:18px; font-weight:bold;" class="d-flex justify-start row-1" >창고명
            <v-spacer></v-spacer> 
            {{row.item.wh_name}} 
          </v-card-text>
          <v-card-text style="font-size:18px; font-weight:bold;" class="d-flex justify-start row-2" >Rack코드 
            <v-spacer></v-spacer> 
            {{row.item.rack_code}} 
          </v-card-text>
          <v-card-text style="font-size:18px; font-weight:bold;" class="d-flex justify-start row-2" >위치코드 
          <v-spacer></v-spacer> 
            {{row.item.bin_code}} 
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="margin-right: 5%;"
            @click="pageBack"
          >
            이전
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            입고
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          입고
        </v-card-title>
        <v-card-text>
          해당 랙으로 입고하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="execWearing"
          >
            입고
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="cancelWearing"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>
  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      var scan_result = this.$route.params.scan_result;
      if(scan_result!=null && scan_result!=undefined) {
        this.locationValue = scan_result;
      }
      var selectedItems = this.$route.params.selectedItems;
      if(selectedItems!=null && selectedItems!=undefined) {
        this.selectedItems = selectedItems
      }
    },
    components: {
      
    },
    data () {
      return {
        selectedBin:[],
        searchModel:null,
        search: null,
        searchItems:[],
        isLoading:false,
        dialogScan:false,
        selectedItems:[],
        dialog:false,
        showBarcode:false,
        locationValue:null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        columns: [
          { text: '창고명', value: 'wh_name' },
          { text: 'Rack코드', value: 'rack_code' },
          { text: '위치코드', value: 'bin_code' },
        ],
        datas: [],

        scanner:null,
        qrbox:null,
        fps:null,
      }
    },
    watch: {
      searchModel (val) {
        
      },
      search (val) {
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== 2222', res.result)
            this.searchItems = res.result
            // if(this.searchItems!=null && this.searchItems.length>0) {
            //   this.searchModel = this.searchItems[0];
            //   this.click();
            // }
            console.log('==== this.searchItems', this.searchItems)
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      // dialogScan(val) {
      //   console.log('Watch dialogScan value', val);
      //   if(val) {
      //     console.log(val)
      //   } else {
      //     this.scanner.stop();
      //   }
      // }
    },
    methods: {
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
        });
      },
      onScanSuccess(qrCodeMessage) {
        console.log('=== qrCodeMessage ', qrCodeMessage)
        if(qrCodeMessage!=null) qrCodeMessage = qrCodeMessage.replace('-', '');
        console.log('=== qrCodeMessage 2', qrCodeMessage)
        this.searchFn(qrCodeMessage);
        this.scanner.stop();
        this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },
      searchFn(val) {
        
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== 2222', res.result)
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0];
              this.click();
            }
            console.log('==== this.searchItems', this.searchItems)
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      execWearing() {
        // item + rack api send
        console.log('===== exec wearing items', this.selectedItems)
        console.log('===== exec wearing bin', this.selectedBin)

        var item_uids = [];
        var item_quans = [];
        for(var i in this.selectedItems) {
          var o = this.selectedItems[i]
          var unique_id = o.unique_id
          var quan = o.quan * 1
          item_uids.push(unique_id)
          item_quans.push(quan)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execWearing';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          item_uids:item_uids,
          item_quans:item_quans,
          bin_uid:this.selectedBin[0]
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            // dialog close
            this.dialog = false
            
            // back to page
            this.$router.push('wearing')
          })
      },

      cancelWearing() {
        this.dialog = false
      },

      pageBack() {
        this.$router.push('wearing')
      },

      click(value) {
        var newRec = {
          unique_id:this.searchModel.unique_id,
          wh_name:this.searchModel.wh_name,
          rack_code:this.searchModel.rack_code,
          bin_code:this.searchModel.bin_code,
          checkbox:true
        }

        this.datas.splice(0, this.datas.length)
        this.datas.push(newRec)
        
        this.selectedBin.splice(0, this.selectedBin.length)
        this.selectedBin.push(this.datas[0].unique_id)

        // search clear
        this.search = null
        this.searchModel = null
        this.searchItems = []
      },

      rowClicked(row) {
        row.checkbox = !row.checkbox
        this.toggleSelection(row.unique_id);
      },
      toggleSelection(keyID) {
        if(this.selectedBin!=null && this.selectedBin.length>0) {
          if(this.selectedBin[0] == keyID) {
            this.selectedBin.splice(0,1)
          } else {
            this.selectedBin.splice(0,1)
            this.selectedBin.push(keyID)
          }
        } else {
            this.selectedBin.push(keyID)
        }
      },

      resetDatas() {
        this.datas = [];
      },

      clear() {
        this.resetDatas();
      }

    }
  }
</script>