<template>
  <div>
    <div class='left'>
      <ul>
        <li id='logo'>
          <div>Inventory</div>
          <div>Manager</div>
      </li>
      <li v-for="(menu, i) in menus" v-bind:key="i">
        <router-link :to='menu.code' >{{ menu.name }}</router-link>
      </li>
      <!-- <li id='company'>
        <div>Welcomt to Inventory</div>
        <div>Seoul Gangnam <br> ... </div>
        <div>@copyright demnodey</div>
      </li> -->
      </ul>
    </div>
    <!-- <img id='left_btn' @click='toggle' src='@/assets/icons/door-in.png'> -->
  </div>
</template>
<script>
import $ from 'jquery'

export default {
  name: 'LeftMenu',
  data () {
    return {
      toggle: this.enter,
      menus:  [
        { code:'home', name:'HOME', link: 'components/views/Home' },
        { code:'login', name:'로그인', link: 'components/main/LoginForm' },
        { code:'wearing', name:'입고', link: 'components/views/Wearing' },
        { code:'release', name:'출고', link: 'components/views/Release' },
        { code:'stockmove', name:'재고이동', link: 'components/views/StockMove' },
        { code:'stocksearch', name:'재고검색', link: 'components/views/StockSearch' },
        //{ code:'test', name:'test', link: 'components/views/Test' }
      ]
    }
  },
  methods: {
    enter: function (el, done) {
      this.toggle = this.leave
      var left = (250 - (parseInt($('#left_btn').css('width')) / 2)) + 'px'
      $('.left').stop().animate({left: '0px'}, 800)
      $('#left_btn').stop().animate({left: left}, 800)
      $('#left_btn').css({transform: 'rotate(180deg)'})
    },
    leave: function (el, done) {
      this.toggle = this.enter
      var left = (0 - (parseInt($('#left_btn').css('width')) / 2)) + 'px'
      $('.left').stop().animate({left: '-250px'}, 800)
      $('#left_btn').stop().animate({left: left}, 800)
      $('#left_btn').css({transform: 'rotate(0deg)'})
    },
    resize: function () {
      $(window).resize(function () {
        var height = (window.innerHeight / 2) + 'px'
        $('#left_btn').css({'top': height})
      })
    }
  },
  created () {
    this.resize()
  }
}
</script>
<style>
  a{color:black}
  a:hover{color:rgba(127, 172, 255, 0.6);}
  .left{
    position:fixed; 
    top:5%; 
    /* left:0px;  */
    left:-20%; 
    width:20%; 
    height:92%; 
    background:rgba(127, 172, 255, 0.6); 
    /* padding:40px 0;  */
    overflow: hidden
  }
  .left ul {padding: 0 30px}
  .left ul li { font-size:25px;  height:75px;} 
  .left ul li#logo {font-family: 'Passion One', cursive; font-size:30px; height:170px;}
  .left ul li#logo div {line-height: 0.8}
  .left ul li#company {font-size:16px; margin-top:150px; }
  #left_btn {position:fixed; top:495px; left:-60px; cursor:pointer}
</style>