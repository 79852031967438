<template>
  <v-app>
    <v-main>
      <div id="content-wrap">
        <Header v-if="!['LOGIN','BARCODE','HOME'].includes($route.name)" :name="$route.meta.title"/>
        <router-view></router-view>
        <LeftMenu v-if="!['LOGIN','BARCODE','HOME'].includes($route.name)"/>
        <!-- <Footer v-if="!['LOGIN','BARCODE'].includes($route.name)"/> -->
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Base from './components/base/Base.vue';

import Header from './components/main/Header.vue'
import LeftMenu from './components/main/LeftMenu.vue'
import Footer from './components/main/Footer.vue'

import '@/assets/style/main.css';
export default {
  name: 'App',
  mounted() {
    var is_login = this.$store.getters.isLogin;
    if(is_login) {
      this.$router.push('home')
    } else {
      this.$router.push('login')
    }
  },
  components: {
    Header:Header,
    LeftMenu:LeftMenu,
    // Footer:Footer,
  },
  watch: {
    $route(to, from) {
      var name = to.name;
      if(name==null || name!='LOGIN') {
        var is_login = this.$store.getters.isLogin;
        if(!is_login) {
          this.$router.push('login')
        }
      }
    }
  },

  data() {
    return {
      
    }
  },

  methods: {
    
  }
};
</script>
