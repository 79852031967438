<template>
  <div class="content" style="height:100%; min-height:100%; position:fixed;">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="padding:5%; overflow-y: scroll; height: 100%;"
    >
      <p style="text-align:center;">바코드를 스캔해주세요</p>
      <v-row>
      <v-col 
      cols="9"
      >
      <v-autocomplete
        label="위치검색"
        type="text"
        :append-outer-icon="'mdi-barcode-scan'"
        ref="rack_barcode"
        v-model="rack_barcode2"
        :items="locationItems"
        :search-input.sync="searchLocation"

        clearable
        hide-details
        hide-selected
        hide-no-data
        return-object

        item-text="bin_code"
        item-value="bin_code"

        :autofocus="true"
        @click:append-outer="openScanner($store.getters.getCodeType)"
        @focus="$event.target.select()"
        @change="searchEvent(rack_barcode2)"
        @keydown.enter="searchEvent(rack_barcode2)"
        @submit="searchEvent(rack_barcode2)"
        @click="searchLocation_void"
        menu-props="{'closeOnContentClick': true}"
      >
      </v-autocomplete>
    </v-col>
    <v-col  cols="3">
      <v-btn @click="searchEvent(rack_barcode2)" style="font-size:10pt;height:90%">입력</v-btn>
    </v-col>
  </v-row>
    <v-col 
    cols="12"
    sm="12"
    hidden
    >
      <v-text-field
        v-model="rack_barcode"
        :append-icon="'mdi-magnify'"
        :append-outer-icon="'mdi-barcode-scan'"
        label="바코드"
        clearable

        @click:append-outer="openScanner($store.getters.getCodeType)"
        @click:append="searchEvent"
        v-on:keydown.enter = "searchEvent"
        :autofocus="true"
      >
      </v-text-field> 
      </v-col>
      <v-text-field
        v-model="wh_name"
        label="창고명(2자리)"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="rack_name"
        label="동(1~4자리)"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="rack_col"
        label="*열(2자리)"
        outlined
        clearable
        required
        :readonly="editBtn == true ? false : true"
        type="number"
      ></v-text-field>
      
      <v-text-field
        v-model="rack_row"
        label="*층(2자리)"
        outlined
        clearable
        required
        :readonly="editBtn == true ? false : true"
        type="number"
      ></v-text-field>
    </v-form>

    <div style="width:100%; display: flex;">
      <div style="width: 33.3%; text-align: center;">
        <v-btn
          text
          @click="registEvent"
          style="width:80%; background-color: rgb(103, 173, 219); color:white;"
          :disabled="addBtn"
        >
          등록
        </v-btn>
      </div>
      <div style="width: 33.3%; text-align: center;" hidden>
        <v-btn
          text
          @click="editEvent"
          style="width:80%; background-color: rgb(103, 173, 219); color:white;"
          :disabled="editBtn"
          hidden
        >
          수정
        </v-btn>
      </div>
      <div style="width: 33.3%; text-align: center;">
        <v-btn
          text
          @click="() => dialogRemoveRack = true"
          style="width:80%; background-color: rgb(103, 173, 219); color:white;"
          :disabled="removeBtn"
        >
          삭제
        </v-btn>
      </div>
    </div>

    <v-dialog
      v-model="dialogRemoveRack"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h4">
          경고
        </v-card-title>
        <v-card-text class="text-h5">
          해당 위치에 대한 모든 정보를 삭제합니다.
        </v-card-text>
        <v-card-text class="text-h5">
          진행하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            style="background-color:#d3d1d1;"
            text
            @click="removeEvent"
          >
            확인
          </v-btn>
          <v-btn
            color="black"
            style="background-color:#d3d1d1;"
            text
            @click="() => dialogRemoveRack = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
            :autofocus="true"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogLoading"
      max-width="400"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{loadingText}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

  </div>
</template>
 
<script>
  import axios from 'axios'
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      
    },
    components: {
      
    },

    watch: {
      searchLocation(val) {
        if(val == null || val.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },
      search (val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('=== res', res);
            console.log('=== result', res.result);
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            // dialog close
            if(this.dialogScan){
              this.dialogScan = false
            }
          })
      },
      dialogScan(val) {
        console.log('Watch dialogScan value', val);
        if(val) {
          console.log(val)
        } else {
          if(this.scan){
          this.scanner.stop();
          }
        }
      }
    },

    data () {
      return {
        valid: true,
        searchLocation:null,
        locationModel:null,
        locationItems:[],
        selectedBin:null,

        searchModel:null,
        searchItems:[],
        search:null,

        item_code:null,
        item_name:null,
        specification:null,
        safe_quan:null,

        rack_name:null,
        wh_name:null,
        location_code:null,
        rack_row:null,
        rack_col:null,

        is_duplicate:false,

        dialogScan:false,
        dialogAlaram:false,
        dialogLoading:false,
        dialogRemoveRack:false,
        alaramText:'',
        loadingText:'',

        rack_barcode:null,
        rack_barcode2:null,

        scanner:null,
        qrbox:null,
        fps:null,

        addBtn:true,
        editBtn:true,
        removeBtn:true,
        
      }
    },

    methods: {
      openScannerWearing() {
        this.setIsScanWearing()
         .then(() => {
           this.startScanWearing()
         })
      },
      setIsScanWearing() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScanWearing() {
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 50} }, this.onScanSuccessWearing);
        });
      },
      onScanSuccessWearing(qrCodeMessage) {
          //if(qrCodeMessage!=null) qrCodeMessage = qrCodeMessage.replace('-', '');
          this.searchFnWearing(qrCodeMessage);
          this.scanner.stop();
          if(this.dialogScan){
            this.dialogScan = false;
          }
      },
      onScanErrorWearing(errorMessage) {
          console.log(errorMessage);
      },
      searchLocation_void() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },
      clickLocation(obj) {
        this.selectedBin = obj.unique_id
      },

      openWearing() {
        this.updateSelectedObject();
        if(this.selectedObject == null || this.selectedObject.length<1) {
          this.dialogNext = true;
          return;
        } else {
          for(var i=0; i<this.selectedObject.length; i++) {
            var obj = this.selectedObject[i]
            var quan = obj.quan
            if(quan<1) {
              this.dialogZero = true;
              return
            }
          }
        }
        this.dialogWearing = true
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          if(this.dialogScan){
            this.dialogScan = false;
          }
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      searchEvent() {
        if(this.rack_barcode2){
          console.log(this.rack_barcode2.bin_code);
          this.searchFn(this.rack_barcode2.bin_code);
        }
        else{
        console.log(this.$refs.rack_barcode.$refs.input.value);
        this.searchFn(this.$refs.rack_barcode.$refs.input.value);
        }
        //this.searchFn(this.rack_barcode);
      },

      searchFn(val) {
        console.log("=-=-=");
        //var val = val_1.bin_code; // autocomplete에서 호출될때 사용
        console.log(val);
        if(val==null || val.length<1) {
          this.dialogAlaram = true
          this.alaramText = '바코드를 확인해주세요'
          return
        }
        //val = val.replace('-', '')
        if(val!=null && val.length != 8) {
          // this.dialogAlaram = true
          // this.alaramText = '바코드 값은 8자리 입니다'
          // return
        }
        this.rack_barcode = val;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          exec_bin_code:this.rack_barcode
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;
        console.log(url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            var result = res.result

            if(result==null || result.length<1) {
              this.addBtn = false
              this.editBtn = true
              this.removeBtn = true

              this.dialogAlaram = true
              this.alaramText = '신규 위치 정보입니다'
              console.log(this.rack_barcode.length-6+1);
              // this.wh_name = this.rack_barcode.slice(0,2)// null
              // this.rack_name = this.rack_barcode.slice(2,4) //null
              // this.rack_col = this.rack_barcode.slice(4,6) //null
              // this.rack_row = this.rack_barcode.slice(6,8)//null
              this.wh_name = this.rack_barcode.slice(0,2)// null
              console.log(this.rack_barcode);
              this.rack_name = this.rack_barcode.slice(2,this.rack_barcode.length-6+2) //null
              console.log(this.rack_name);
              this.rack_col = this.rack_barcode.slice(-4,-2) //null
              this.rack_row = this.rack_barcode.slice(-2)//null
            } else {
              var data = result[0]

              this.wh_name = data.wh_name
              //this.rack_name = data.rack_name.slice(-2);
              this.rack_name = data.rack_name.slice(2,(this.rack_barcode.length-2));
              this.rack_col = data.bin_col
              this.rack_row = data.bin_row

              this.addBtn = true
              this.editBtn = false
              this.removeBtn = false

            }
            
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            
          })
      },

      clickCombo(val) {
        if(val == null) return;
        console.log('==== @@@@@', val);

        this.wh_name = this.searchModel.wh_name
        this.rack_name = this.searchModel.rack_name
        this.rack_col = this.searchModel.bin_col
        this.rack_row = this.searchModel.bin_row
      },

      pageBack() {
        this.$router.push('home')
      },

      IsDuplicateUpdate() {
        this.is_duplicate = false
      },
      
      checkDuplicate() {
        this.is_duplicate = true
      },

      registEvent() {
        if(this.rack_barcode==null || this.rack_barcode.length<1 ||
           this.rack_row==null || this.rack_row < 1 ||
           this.rack_col==null || this.rack_col < 1
        ) {
          this.dialogAlaram = true
          this.alaramText = '바코드 및 필수 정보를 확인해주세요'
          return
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=manageRackData';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          type:'ADD',
          rack_barcode:this.rack_barcode,
          wh_name:this.wh_name,
          rack_name:(this.wh_name + this.rack_name),
          rack_col:this.rack_col,
          rack_row:this.rack_row,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;
        console.log(url);

        this.dialogLoading = true
        this.loadingText = '등록중입니다'

        fetch(url)
          .then(res => {
            this.dialogAlaram = true
            this.alaramText = '등록완료'

            this.resetDatas()
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogLoading = false
          })
      },

      editEvent() {
        if(this.rack_barcode==null || this.rack_barcode.length<1 ||
           this.rack_row==null || this.rack_row < 1 ||
           this.rack_col==null || this.rack_col < 1
        ) {
          this.dialogAlaram = true
          this.alaramText = '바코드 및 필수 정보를 확인해주세요'
          return
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=manageRackData';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          type:'EDIT',
          rack_barcode:this.rack_barcode,
          wh_name:this.wh_name,
          rack_name:this.rack_name,
          rack_col:this.rack_col,
          rack_row:this.rack_row,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        this.dialogLoading = true
        this.loadingText = '수정중입니다'

        fetch(url)
          .then(res => {
            this.dialogAlaram = true
            this.alaramText = '수정완료'

            this.resetDatas()
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogLoading = false
          })
      },

      removeEvent() {
        if(this.rack_barcode==null || this.rack_barcode.length<1 ||
           this.rack_row==null || this.rack_row < 1 ||
           this.rack_col==null || this.rack_col < 1
        ) {
          this.dialogAlaram = true
          this.alaramText = '바코드 및 필수 정보를 확인해주세요'
          return
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=manageRackData';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          type:'REMOVE',
          rack_barcode:this.rack_barcode,
          wh_name:this.wh_name,
          rack_name:this.rack_name,
          rack_col:this.rack_col,
          rack_row:this.rack_row,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        this.dialogLoading = true
        this.loadingText = '삭제중입니다'

        fetch(url)
          .then(res => {
            this.dialogAlaram = true
            this.alaramText = '삭제완료'

            this.resetDatas()
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogLoading = false
            this.dialogRemoveRack = false
          })
      },

      resetDatas() {
        this.rack_barcode = null
        this.wh_name = null
        this.rack_name = null
        this.rack_col = null
        this.rack_row = null

        this.addBtn = true
        this.editBtn = true
        this.removeBtn = true
      },

      download_event() {
        console.log('download_event')

        var url = this.$vBACKEND_URL + '/stock.do?method=stockExcelDownLoad';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const excelPath = res.result;
            location.href = this.$vCONTENT_URL + "/excel/" + excelPath
          })
          .catch(err => {
            console.log(err)
          })
      },

      upload_event() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/stock.do?method=locationExcelUpload'
        var me = this

        // Event
        input.onchange = function() {
          const formData = new FormData()
          formData.append('file', this.files[0])
          formData.append('uid_company', me.$store.getters.getUidCompany)
          formData.append('user_uid', me.$store.getters.getUserUid)
          formData.append('user_id', me.$store.getters.getUserId)
          formData.append('user_name', me.$store.getters.getUserName)

          axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.log(err)
                })
        }
      },

      barcode_down_event() {
        console.log('barcode_down_event')
      },

    }
  }
</script>
