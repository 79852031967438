<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="selectType"
            :items="selectTypes"
            menu-props="auto"
            outlined
            hide-details
            @change="typeClick"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-autocomplete
            v-model="searchModel"
            :label="selectType + ' 검색'"
            :items="searchItems"
            :search-input.sync="search"
            clearable
            hide-details
            :item-text="searchText"
            :item-value="searchValue"
            return-object
            type="text"
            @change="click"
            :autofocus="true"
          >
            <!-- <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left>
                  mdi-format-list-bulleted-square
                </v-icon>
                <span v-text="item.wh_code"></span>
              </v-chip>
            </template> -->
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    <div style="width:100%; height:20%;">
      <v-card
        class="mx-auto"
        color="white" 
        dark
        width="100%"
        style="margin-bottom:10px; backgroundColor:#9e6860 !important;"
      >
        <v-card-title style="font-size:20pt;">
          종수
          <v-spacer></v-spacer>
          {{sumDatas.count}}
        </v-card-title>
        <v-card-title style="font-size:20pt;">
          수량
          <v-spacer></v-spacer>
          {{sumDatas.total_quan}}
        </v-card-title>
      </v-card>
    </div>

    <v-data-table
      v-model="selectedRecords"
      :headers="columns"
      :items="datas"
      item-key="id"
      hide-default-footer
      hide-default-header
      no-data-text=""
      class="elevation-1"
      style="width:100%; height:65%; overflow:scroll;"
      disable-pagination
    >

      <template v-slot:item="row" >
        <v-card
          class="mx-auto"
          color="#264B8C"
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.id"
        >
          <v-card-title style="font-size:20px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1">
            {{row.item.item_code}}
            <v-spacer></v-spacer>
            {{row.item.bin_code}} 
          </v-card-title>
          <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1">
            {{row.item.item_name}}<br>
            {{row.item.specification}}<br>
            {{row.item.detail_info}}
            <v-spacer></v-spacer>
            <br><br>
            <v-btn class="mx-1" color="#46B2E1">
              {{row.item.sum_location_quan * 1}}
            </v-btn>
          </v-card-text>
        </v-card>
      </template>

      <!-- <template v-slot:item="row" >
        <v-toolbar
          light
          color='#cddaed'
        >
        </v-toolbar>
        <v-card
          class="mx-auto"
          color="white" 
          dark
          width="100%"
          style="margin-bottom:10px; backgroundColor:#5d9b9e !important;"
          :key="row.item.id"
        >
          <v-card-title>
            {{row.item.item_code}}
            <v-spacer></v-spacer> 
            <v-btn class="mx-1" color="#46B2E1">
              {{row.item.sum_location_quan * 1}}
            </v-btn>
          </v-card-title>
          <v-card-text>
            {{row.item.item_name + ' / ' + row.item.specification}} 
          </v-card-text>
        </v-card>
      </template> -->

    </v-data-table>
  </div>
</template>
 
<script>
  // import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      // alert('준비중입니다')
      // this.$router.replace('home');
      this.initSearch();
    },
    components: {
      
    },

    watch: {
      search(val) {
        // console.log('====val', val);
        // if(val == null || val.length < 1) return;

        var type = "";
        if(this.selectType=='BIN') type = "BIN"
        if(this.selectType=='창고') type = "WHOUSE"
        if(this.selectType=='동') type = "RACK"

        var url = this.$vBACKEND_URL + '/stock.do?method=searchStockTypeCombo';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          selectType:type,
          value:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.datas
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    data () {
      return {
        // selectType:'창고',
        // selectTypes:['창고','동'],
        selectType:'BIN',
        selectTypes:['BIN','창고','동'],

        searchModel:null,
        searchItems:[],
        search:null,
        searchText:'bin_code',
        searchValue:'bin_code',

        sumDatas:[],
        datas:[],

        selectedRecords:[],

        columns: [
          { text: 'LOTNO', value: 'lotno', align:'center' },
          { text: '품번', value: 'item_code', align:'center' },
          { text: '품명', value: 'item_name', align:'center' },
          { text: '규격', value: 'specification', align:'center' },
          { text: '수량', value: 'sum_location_quan', align:'center' },
        ],
      }
    },

    methods: {
      initSearch() {
        this.searchFn('');
      },
      searchFn(val) {
        var type = "";
        if(this.selectType=='BIN') type = "BIN"
        if(this.selectType=='창고') type = "WHOUSE"
        if(this.selectType=='동') type = "RACK"

        var url = this.$vBACKEND_URL + '/stock.do?method=searchStockTypeCombo';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          selectType:type,
          value:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.datas
          })
          .catch(err => {
            console.log(err)
          })
      },
      click(val) {
        if(val == null || val.length < 1) return;

        var type = "";
        if(this.selectType=='BIN') {
          type = "BIN"
          val = this.searchModel.bin_code
        }
        if(this.selectType=='창고') {
          type = "WHOUSE"
          val = this.searchModel.wh_code
        }
        if(this.selectType=='동') {
          type = "RACK"
          val = this.searchModel.rack_code
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=searchStockTypeDatas';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          selectType:type,
          value:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('=== res.sum', res.sum)
            console.log('=== res.datas', res.datas)
            this.sumDatas = res.sum
            this.datas = res.datas
            // this.searchItems = res.datas
          })
          .catch(err => {
            console.log(err)
          })
      },
      typeClick(val) {
        this.searchModel = null
        this.searchItems = []
        this.search = null

        this.datas = []
        this.sumDatas = []
        this.selectedRecords = []

        switch(val) {
          case 'BIN':
            this.searchText = 'bin_code'
            this.searchValue = 'bin_code'
            break;
          case '창고':
            this.searchText = 'wh_code'
            this.searchValue = 'wh_code'
            break;
          case '동':
            this.searchText = 'rack_code'
            this.searchValue = 'rack_code'
            break;
        }

        this.searchFn('');
      },
    }
  }
</script>