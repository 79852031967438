<!-- Home.vue -->
 
<template>
  <div id="home" class="content" style="height:100%;">
    <v-container style="height:10%;">
      <v-icon
        large
        color="#2A5786"
        @click="drawer = !drawer"
      >
        mdi-menu
      </v-icon>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
      >
        <v-list
          nav
          dense
        >
          <v-list-item
            v-for="(menu, i) in cards"
            :key="i"
            link
            @click="goToMenu(menu.code)"
            style="border:1px solid; background: linear-gradient(rgb(253 253 253), rgb(243 243 243));"
          >
            <img :src="menu.icon" style="width:20%;" />
            <v-list-item-content>
              <v-list-item-title style="margin:10%; color:black; font-size:15px; overflow: visible;">{{menu.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-navigation-drawer>
      <v-row style="height:50%;">
        <v-col style="text-align:right;" cols="12" md="12" sm="12">
          <img src="../../assets/logos/LogiForm_logo.png" width="50%" height="60%" />
          <v-icon
            large
            colo="green"
            style="font-size:8vh; color:#2A5786; "
            @click="user_sheet = true"
          >
            mdi-account-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-container> 

    <v-container style="height:90%; margin-top: 10%;">
      <v-row style="height:25%;">
        <v-col cols="6" sm="6" md="6" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('WEARING')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:8vw;">
              입고
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/warehouse.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
          <!-- <v-card style="height:100%;" @click="goToMenu('BARCODE_SCAN')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:2em;">
              Barcode Scan
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/door-in.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card> -->
        </v-col>
        <v-col cols="6" sm="6" md="6" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('RELEASE')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:8vw;">
              출고
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/shipped.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row style="height:20%;" >
        <v-col cols="12" sm="12" md="12" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('STOCKMOVE')">
            <v-card-title class="font-weight-black" style="height:40%; color:#2A5786; font-size:8vw;">
              재고위치조정
            </v-card-title>
            <v-card-text style="text-align:right; height:60%;">
              <img :src="require('@/assets/icons/move-stock.png')" style="width:20%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row style="height:22.5%;" >
        <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('SEARCH_ITEM')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              품목조회
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/search_item.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" sm="4" md="4" style="height:100%;"> 
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('STOCK_MGMT')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              재고현황
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/search_location.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col cols="4" sm="4" md="4" style="height:100%;"> 
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('SEARCH_LOCATION')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:1.1em;">
              위치별<br/>조회
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/search_location.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col> -->
        <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('SEARCH_LOADRATE')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              적재율
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/search_rate.png')" style="width:40%; height:100%;"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row style="height:22.5%;" >
        <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('SELECT_ITEM_VIEW')">
          <!-- <v-card style="height:100%;" @click="goToMenu('REGIST_ITEM')"> -->
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              품목관리
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/mgmt_item.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('REGIST_LOCATION_BARCODE')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              위치관리
            </v-card-title>
            <v-card-text style="text-align:right; height:50%;">
              <img :src="require('@/assets/icons/mgmt_location.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col>

        <!---
        <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%; border-radius: 3%;" @click="goToMenu('Test2')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:5vw;">
              TEST-CODE
            </v-card-title>
            <v-card-text style="text-align:right; width:100%; height:50%;">
              
            </v-card-text>
          </v-card>
        </v-col>
        -->
        <!-- <v-col cols="4" sm="4" md="4" style="height:100%;">
          <v-card style="height:100%;" @click="goToMenu('REGIST_LOCATION_MANUAL')">
            <v-card-title class="font-weight-black" style="height:50%; color:#2A5786; font-size:1em;">
              위치등록<br>수동
            </v-card-title>
            <v-card-text style="text-align:right; width:100%; height:50%;">
              <img :src="require('@/assets/icons/barcode.png')" style="width:40%; height:100%;" />
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>

    </v-container>

    <v-dialog
      v-model="dialogLogout"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5" style="font-weight: bold;">
          로그아웃
        </v-card-title>
        <v-card-text class="text-h7" style="font-weight: bold;">
          로그인 페이지로 돌아가시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="logout_confirm"
          >
            로그아웃
          </v-btn>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="dialogLogout = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet 
      v-model="user_sheet" 
    >
      <v-container
        class="text-center"
        width="100%"
        style="background:#e5e5e5;"
        scrollable
      >
        <!-- <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="manageUser"
          :hidden="mgmtUser"
        >
          사용자 관리
        </v-btn> -->

        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="manageUser"
        >
          사용자 정보
        </v-btn>

        <!-- <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="user_sheet = !user_sheet"
        >
          회사정보
        </v-btn> -->

        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="dialogLogout = true"
        >
          로그아웃
        </v-btn>

      </v-container>
    </v-bottom-sheet>

    <UserInfo 
      v-bind:userInfoDialog="showDialog"
      v-model="showDialog"
      @close:dialog="val => showDialog = val"
    />
  </div>

</template>
 
<script>

import UserInfo from './UserInfoView.vue'

export default {
  name:'Home',
  data () {
    return {
      drawer:false,
      group: null,
      dialogLogout:false,
      showDialog:false,
      user_sheet:false,
      mgmtUser:true,
      cards:  [
        // { code:'HOME', name:'HOME', link: 'components/views/Home', icon:require('@/assets/icons/house.png') },
        // { code:'login', name:'로그인', link: 'components/main/LoginForm', icon:require('@/assets/icons/door-in.png') },
        { code:'WEARING', name:'입고', link: 'components/views/Wearing', icon:require('@/assets/icons/warehouse.png') },
        { code:'RELEASE', name:'출고', link: 'components/views/Release', icon:require('@/assets/icons/shipped.png') },
        { code:'STOCKMOVE', name:'재고위치조정', link: 'components/views/StockMove', icon:require('@/assets/icons/move-stock.png') },
        { code:'SEARCH_ITEM', name:'품목조회', link: 'components/views/SearchItem', icon:require('@/assets/icons/search_item.png') },
        { code:'SEARCH_LOCATION', name:'위치별조회', link: 'components/views/SearchLocation', icon:require('@/assets/icons/search_location.png') },
        { code:'SEARCH_LOADRATE', name:'적재율', link: 'components/views/SearchLoadRate', icon:require('@/assets/icons/search_rate.png') },
        { code:'SELECT_ITEM_VIEW', name:'품목관리', link: 'components/views/SelectItemView', icon:require('@/assets/icons/mgmt_item.png') },
        { code:'REGIST_LOCATION_BARCODE', name:'위치관리', link: 'components/views/RegistLocationBarcode', icon:require('@/assets/icons/mgmt_location.png') },
        // { code:'REGIST_LOCATION_MANUAL', name:'위치등록 수정', link: 'components/views/RegistLocationManual', icon:require('@/assets/icons/barcode.png') },
        //{ code:'test2', name:'test', link: 'components/views/Test2', icon:require('@/assets/icons/mgmt_location.png') }
      ],
      
    }
  },
  setup() {
    
  },
  watch: {
    group () {
      this.drawer = false
    },

    showDialog() {
      console.log('this.showDialog', this.showDialog)
    }
  },
  components: {
    UserInfo:UserInfo
  },
  mounted() {
    this.getUserId()
  },
  methods: {
    goToMenu(path) {
      this.$router.push(path);
    },

    logout_confirm() {
      this.dialogLogout = false
      this.user_sheet = false
      this.$store.commit('clearUserInfo')
      this.$router.replace('login')
    },

    manageUser() {
      this.user_sheet = false
      this.showDialog = true
    },

    closeShowDialog() {
      alert('closeShowDialog')
    },

    getUserId() {
      const cur_user_id = this.$store.getters.getUserId
      if(cur_user_id!=null && (cur_user_id == 'admin' || cur_user_id == 'root')) {
        this.mgmtUser = false
      } else {
        this.mgmtUser = true
      }
    },
  },
};
</script>