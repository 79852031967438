import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './components/router/index'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import VScandit from 'vue-scandit';

Vue.use(VueRouter)
Vue.use(Vuex);
Vue.use(VScandit);

Vue.prototype.$vBACKEND_URL = 'https://api.hblwms.co.kr'
Vue.prototype.$vCONTENT_URL = 'https://api.hblwms.co.kr'

// Vue.prototype.$vBACKEND_URL = 'http://14.45.14.42:1003'
// Vue.prototype.$vCONTENT_URL = 'http://14.45.14.42:1003'

// Vue.prototype.$vBACKEND_URL = 'http://localhost:7080'
// Vue.prototype.$vCONTENT_URL = 'http://localhost'

// Vue.prototype.$vBACKEND_URL = 'http://172.30.1.34:7080'
// Vue.prototype.$vCONTENT_URL = 'http://172.30.1.34'

Vue.config.productionTip = false

const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    uid_company:null,   // 사용자 회사 UID
    user_uid:-1,        // 사용자 UID
    user_id:null,       // 사용자 아이디
    user_name:null,     // 사용자명
    cp_code:null,       // 회사코드
    cp_name:null,       // 회사명
    accessToken:null,   // 접근토큰
    menuList:[],        // 메뉴 데이터
    codeType:null,      // 코드 셋팅

    is_saveId:false,    // 아이디 저장 여부
    is_saveLogin:false  // 로그인 유지 여부
  },
  getters: {
    isLogin(state) {
      if(state.user_uid != null && state.user_uid > 1) {
        return true;
      } else {
        return false;
      }
    },
    getLoginDatas(state) {
      return state
    },
    getUidCompany(state) {
      return state.uid_company
    },
    getUserUid(state) {
      return state.user_uid
    },
    getUserId(state) {
      return state.user_id
    },
    getUserName(state) {
      return state.user_name
    },
    getCodeType(state) {
      return state.codeType
    }
    // getMenuList(state) {
    //   return state
    // }
  },
  mutations: {
    setLoginDatas(state, obj) {
      state.uid_company = obj.uid_company;
      state.user_uid = obj.user_uid;
      state.user_id = obj.user_id;
      state.user_name = obj.user_name;
      state.cp_code = obj.cp_code;
      state.cp_name = obj.cp_name;
      state.codeType = obj.codeType
      // state.accessToken = obj.accessToken;
    },
    setCompanyName(state, value) {
      state.cp_code = value;
    },
    getLoginDatas(state) {
      return state;
    },
    clearUserInfo(state) {
      // 회사코드 + 아이디 빼고 제거
      state.uid_company = null;
      state.user_uid = null;
      state.user_name = null;
      state.accessToken = null;
      state.menuList = [];
    },
    setMenuList(state, menus) {
      state.menuList = menus
    },
    setSaveId(state, value) {
      state.is_saveId = value
    },
    setSaveLogin(state, value) {
      state.is_saveLogin = value
    }

  }
})

new Vue({
  vuetify,
  store,
  render: h => h(App),
  router
}).$mount('#app')
