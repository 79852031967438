<!-- Release.vue -->
 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"
            class="search-item-class"
            
            clearable
            hide-details
            hide-selected
            hide-no-data
            item-text="search_name"
            item-value="unique_id"
            return-object
            label="품번/품명 검색"
            @click:append="getDatas"
            @click:append-outer="clickScanType($store.getters.getCodeType)"
            :append-outer-icon="'mdi-barcode-scan'"
            :append-icon="'mdi-magnify'"
            @change="click"
            @click="search_void"
            :autofocus="true"
          >
            <!-- <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left>
                  mdi-format-list-bulleted-square
                </v-icon>
                <span v-text="item.search_name"></span>
              </v-chip>
            </template> -->
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- <v-row style="margin-top:0px; width:100%;">
        <v-col cols="12">
          <v-btn
            width="100%"
            color='orange'
            @click="getDatas"
          >
            <v-card-text>
              조회
            </v-card-text>
          </v-btn>
        </v-col>
      </v-row> -->
    </v-container>
      <template>
        <v-row style="margin:1px; justify-content: space-between;" hidden>
          <v-btn 
            icon
            x-large
            style="width:25%;"
            :color="eventGubun == 'WEARING' ? 'blue' : ''"
            @click="clickEventGubun('WEARING')"
          >
            <v-icon size="50" >
              mdi-cart-plus
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            x-large
            style="width:25%;"
            :color="eventGubun == 'RELEASE' ? 'blue' : ''"
            @click="clickEventGubun('RELEASE')"
          >
            <v-icon size="50">
              mdi-cart-minus
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            x-large
            style="width:25%;"
            :color="eventGubun == 'STOCKMOVE' ? 'blue' : ''"
            @click="clickEventGubun('STOCKMOVE')"
          >
            <!-- <v-icon size="50">
              mdi-cart-arrow-right
            </v-icon>
          </v-btn>
          <v-btn 
            icon
            x-large
            style="width:25%;"
            :color="eventGubun == 'SAFEQUAN' ? 'blue' : ''"
            @click="clickEventGubun('SAFEQUAN'), getSafeData"
          > -->
            <v-icon size="50">
              mdi-alert-outline
            </v-icon>
          </v-btn>
        </v-row>
        <v-row style="margin:1px; justify-content: space-between;" hidden>
          <v-card
            class="mx-auto"
            :color="dateType == 'WEEK' ? '#5A8EF6' : 'white'"
            width="25%"
            style="text-align:center;"
            @click="clickDateType('WEEK')"
          >
            <v-card-text
              :style="dateType == 'WEEK' ? 'font-size:14px; color:white;' : 'font-size:14px; color:black;'"
            >
              1주일
            </v-card-text>
          </v-card>
          
          <v-card
            class="mx-auto"
            :color="dateType == 'MONTH' ? '#5A8EF6' : 'white'"
            width="25%"
            style="text-align:center;"
            @click="clickDateType('MONTH')"
          >
            <v-card-text
              :style="dateType == 'MONTH' ? 'font-size:14px; color:white;' : 'font-size:14px; color:black;'"
            >
              한달
            </v-card-text>
          </v-card>

          <v-card
            class="mx-auto"
            :color="dateType == 'RANGE' ? '#5A8EF6' : 'white'"
            width="25%"
            style="text-align:center;"
            @click="clickDateType('RANGE'), dialogSelect = true"
          >
            <v-card-text
              :style="dateType == 'RANGE' ? 'font-size:14px; color:white;' : 'font-size:14px; color:black;'"
            >
              기간선택
            </v-card-text>
          </v-card>

        </v-row>
      </template>

      <div style="width:100%; height:20%;">
        <v-card
          class="mx-auto"
          color="white" 
          dark
          width="100%"
          style="margin-bottom:10px; backgroundColor:#9e6860 !important;"
        >
          <v-card-title style="font-size:20pt;" hidden>
            항목수
            <v-spacer></v-spacer>
            {{ this.tot_count }}
          </v-card-title>
          <v-card-title style="font-size:20pt;">
            총 수량
            <v-spacer></v-spacer>
            {{this.tot_quan}}
          </v-card-title>
        </v-card>
      </div>
  
  
      <v-data-table
        v-model="selectedItem"
        :headers="columns"
        :mobile-breakpoint="1024"
        :items="datas"
        hide-default-footer
        :hidden="viewData1"
        class="elevation-1"
        style="width:100%; height:75%; overflow:scroll;"
        disable-pagination
      >
        <template v-slot:item="row">
          <v-card
            class="mx-auto"
            color="#264b8c"
            width="100%"
            style="margin-bottom:10px;"
            :key="row.item.unique_id"
          >
            <v-card-title style="font-size:20px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              {{row.item.item_code}}
              <v-spacer></v-spacer> 
              {{row.item.bin_code}}
            </v-card-title>
            <v-card-text style="font-size:18px; font-weight:300; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              {{row.item.item_name}}<br>
              {{row.item.specification}}<br>
              {{row.item.detail_info}}
            </v-card-text>
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-2" >
              {{row.item.is_inout == 'IN' ? '입고:' : '출고:'}} {{row.item.his_date}}
              <v-spacer></v-spacer>
              수량: {{ Math.abs(row.item.his_quan) }}
            </v-card-text>
          </v-card>
          
        </template>
      </v-data-table>

      <v-data-table
        v-model="selectedItem"
        :headers="columns"
        :mobile-breakpoint="1024"
        :items="datas"
        hide-default-footer
        :hidden="viewData2"
        class="elevation-1"
        style="width:100%; height:75%; overflow:scroll;"
        disable-pagination
      >
        <template v-slot:item="row" >
          <v-card
            class="mx-auto"
            color="#264b8c"
            width="100%"
            style="margin-bottom:10px;"
            :key="row.item.unique_id"
            @click="setQuan(row.item)"
          >
            <v-card-title style="font-size:20px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              {{row.item.item_name}}
              <v-spacer></v-spacer> 
              {{row.item.bin_code}}
            </v-card-title>
            <v-card-text style="font-size:18px; font-weight:300; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              {{row.item.lotno}}<br>
              {{row.item.item_name}}<br>
              {{row.item.specification}}<br>
              {{row.item.detail_info}}
              <v-spacer></v-spacer>
              <br><br>
              수량: {{ Math.abs(row.item.quan) }}
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>

      <v-data-table
        v-model="selectedItem"
        :headers="columns"
        :mobile-breakpoint="1024"
        :items="datas"
        hide-default-footer
        :hidden="viewData3"
        class="elevation-1"
        style="width:100%; height:75%; overflow:scroll;"
        disable-pagination
      >
        <template v-slot:item="row" >
          <v-card
            class="mx-auto"
            color="#264b8c"
            width="100%"
            style="margin-bottom:10px;"
            :key="row.item.unique_id"
          >
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;{{row.item.item_name}}
              <v-spacer></v-spacer> 
              안전재고: {{ row.item.safe_quan }}
            </v-card-text>
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-2" >
              &nbsp;{{row.item.specification}} 
              <v-spacer></v-spacer> 
              현재수량: {{ Math.abs(row.item.sum_quan) }}
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    <!-- <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="margin-right: 5%;"
            @click="() => this.$router.push('home')"
          >
            이전
          </v-btn>
        </v-col>
      </v-row>
    </div> -->
    <div class="text-center pt-2" hidden>
      <v-row
      rows="5"
      sm="3"
      >
        <v-col
        cols="12"
        sm="12"
        >

            <v-card-title>
              총재고수량
              <v-spacer></v-spacer>
              0
            </v-card-title>
        </v-col>
      </v-row>
    </div>


    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogRazer"
      width="50%"
      max-width="1000px"
      :scrollable="false"
    >
      <div style="width:100%; height:80%; background:white; padding:2%;">
        <v-text-field
          v-model="razerValue"
          label="검색"
          autofocus
        >
        </v-text-field>
      </div>

      <v-snackbar
          v-model="snackRazer"
          :timeout="snackTimeRazer"
          :color="snackColorRazer"
      >
          {{ snackTextRazer }}
      </v-snackbar>
    </v-dialog>

    <v-dialog
      v-model="dialogSelect"
      persistent
      light
      max-width="500"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  ref="start_menu"
                  v-model="start_menu"
                  :close-on-content-click="false"
                  :return-value.sync="start_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="시작일"
                    v-model="start_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  no-title
                  scrollable
                  locale="ko"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_menu.save(start_date)"
                  >
                    확인
                  </v-btn>
                </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  ref="end_menu"
                  v-model="end_menu"
                  :close-on-content-click="false"
                  :return-value.sync="end_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="종료일"
                    v-model="end_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  no-title
                  scrollable
                  locale="ko"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_menu.save(end_date)"
                  >
                    확인
                  </v-btn>
                </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogSelect = false"
          >
            확인
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogSelect = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogQuan"
      persistent
      light
      max-width="500"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="location_quan"
              label="수량"
              outlined
              type="number"
              :autofocus="true"
            >
            </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="execUpdateQuan"
          >
            확인
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogQuan = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet
      v-model="scan_sheet"
    >
      <div style="width:100%; height:100%; background:white;">
        <v-container
          class="text-center"
          width="100%"
          scrollable
        >
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('BARCODE')"
          >
            바코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('QRCODE')"
          >
            QR코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('RAZER')"
          >
            레이저 스캐너
          </v-btn>
        </v-container>
      </div>
    </v-bottom-sheet>

  </div>
  
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      
    },
    components: {
      
    },

    watch: {
      searchModel(val) {
        if(val!=null) {
          this.selectedUidItem = val.unique_id
        } else {
          this.selectedUidItem = null
        }
      },

      search(val) {
        if(val == null || val.length < 1) return;
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:val
          // item_name:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      dialogScan() {
        if(!this.dialogScan) {
          const isScanning = this.scanner.isScanning;
          if(isScanning) this.scanner.stop();
        }
      },

      razerValue() {
        if(this.razerValue!=null && this.razerValue.length>0) {
          setTimeout(function() {
            this.searchFn(this.razerValue);
          }.bind(this), 1500);
        }
      }
    },

    data () {
      return {
        search:null,
        searchModel:null,
        searchItems:[],
        isLoading:false,
        selectedUidItem:null,
        setQuanUid:null,
        location_quan:null,

        dialogScan:false,
        dialogSelect:false,
        dialogAlaram:false,
        dialogQuan:false,

        viewData1:true,
        viewData2:true,
        viewData3:true,

        columns:[],
        datas:[],
        selectedItem:[],

        dates:[],

        scanner:null,
        qrbox:null,
        fps:null,

        start_menu:false,
        end_menu:false,

        start_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        alaramText:'',

        // dateType:'MONTH',
        dateType:null,
        eventGubun:null,

        dataConcat:false,

        scan_sheet:false,
        scanType:'',
        dialogRazer:false,
        razerValue:'',

        snack:false,
        snackTime:3000,
        snackColor:'',
        snackText:'',

        snackRazer:false,
        snackTimeRazer:3000,
        snackColorRazer:'',
        snackTextRazer:'',

        sumDatas:[],
        tot_quan:0,
        tot_count:0,
      }
    },

    methods: {
      search_void() {
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      clickScanType(type) {
        console.log('=== clickScanType type', type)
        this.scan_sheet = false;
        this.scanType = type;

        switch(type) {
          case 'BARCODE':
          case 'QRCODE':
            this.openScanner(type);
            break;
          case 'RAZER':
            this.dialogRazer = true
            break;
        }
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
            // this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 50} }, this.onScanSuccess);

            
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      readRazerSuccess(value) {
        this.searchFn(value);
        // this.dialogRazer = false;
        // this.razerValue = '';
      },

      searchFn(val) {
        if(val == null || val.length < 1) return;
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          barcode:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0];
              this.click(this.searchItems[0])
              this.getDatas()
            } else {
              switch(this.scanType) {
                case 'RAZER':
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snackRazer = true
                  this.snackTimeRazer = 5000
                  this.snackColorRazer = 'error'
                  this.snackTextRazer = '데이터가 존재하지 않습니다.'
                  break;
                default:
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snack = true
                  this.snackTime = 5000
                  this.snackColor = 'error'
                  this.snackText = '데이터가 존재하지 않습니다.'
                  break;
              }
            }
            this.razerValue = '';
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      click() {
        if(this.searchModel!=null) {
          this.selectedUidItem = this.searchModel.unique_id
        }
        this.getDatas();
      },

      clickEventGubun(type) {
        if(this.eventGubun!=null && this.eventGubun == type) {
          this.eventGubun = null
        } else {
          this.eventGubun = type
        }
      },

      clickDateType(type) {
        if(this.dateType!=null && this.dateType == type) {
          this.dateType = null
        } else {
          this.dateType = type
        }
      },

      getDatas() {
        let apiType = 'ALL'
        const dateType = this.dateType
        const eventGubun = this.eventGubun

        var url = this.$vBACKEND_URL + '/stock.do?method=readItemHistory';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        // date type condition
        if(dateType!=null && dateType.length>0) {
          switch(dateType) {
            case 'WEEK':
            case 'MONTH':
              obj['type'] = dateType
              break;
            case 'RANGE':
              if(this.start_date == null || this.start_date.length < 1 || 
                this.end_date == null || this.end_date.length < 1) {
                
                this.alaramText = '시작/종료일을 확인해주세요'
                this.dialogAlaram = true
                return;
              }

              if(this.start_date > this.end_date) {
                this.alaramText = '시작/종료일을 확인해주세요'
                this.dialogAlaram = true
                return;
              }

              obj['start_date'] = this.start_date
              obj['end_date'] = this.end_date
              obj['type'] = dateType

              this.dialogSelect = false;
              break;
          }
          apiType = 'DATE'
        }

        // event gubun condition
        if(eventGubun!=null && eventGubun.length>0) {
          switch(eventGubun) {
            case 'WEARING':
              obj['gubun'] = 'IN'
              break;
            case 'RELEASE':
              obj['gubun'] = 'OUT'
              break;
            case 'STOCKMOVE':
              obj['gubun'] = 'MOVE'
              break;
            case 'SAFEQUAN':
              this.getSafeData();
              return;
          }
          apiType = 'DATE'
        }

        // search item condition
        if(this.selectedUidItem!=null && this.selectedUidItem > 1) {
          obj['uid_item'] = this.selectedUidItem
          if(apiType == 'ALL') apiType = 'ITEM'
        }

        obj['apiType'] = apiType

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const datas = res.datas;
            var newArr = this.datas.concat(datas)
            this.sumDatas = res.datas;
            if(this.dataConcat) {
              this.datas = newArr;
            } else {
              this.datas = datas;
            }
            this.showDataTable(apiType)

            console.log(this.sumDatas);
            this.get_total_quan();
            console.log(this.tot_quan);
          })
      },

      get_total_quan(){
        var t_quan=0;
        var t_cnt=0;
        for(var key in this.sumDatas) {
          t_quan = t_quan + this.sumDatas[key].quan;
          t_cnt++;
        }
        this.tot_quan = t_quan;
        this.tot_count = t_cnt;
      },
      showDataTable(apiType) {
        switch(apiType) {
          case 'ITEM':
            this.viewData1 = true
            this.viewData2 = false
            this.viewData3 = true
            break;
          case 'DATE':
            this.viewData1 = false
            this.viewData2 = true
            this.viewData3 = true
            break;
          case 'ALL':
            this.viewData1 = false
            this.viewData2 = true
            this.viewData3 = true
            break;
          case 'SAFE':
            this.viewData1 = true
            this.viewData2 = true
            this.viewData3 = false
            break;
        }
      },

      getSafeData() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readItemSafeQuan';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        // search item condition
        if(this.selectedUidItem!=null && this.selectedUidItem > 1) {
          obj['uid_item'] = this.selectedUidItem
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const datas = res.datas;
            var newArr = this.datas.concat(datas)
            if(this.dataConcat) {
              this.datas = newArr;
            } else {
              this.datas = datas;
            }

            this.showDataTable('SAFE')
          })
      },

      setQuan(item) {
        console.log('seq quan', item.unique_id)
        this.dialogQuan = true
        this.setQuanUid = item.unique_id
        this.location_quan = item.quan
      },

      execUpdateQuan() {

        var url = this.$vBACKEND_URL + '/stock.do?method=execUpdateQuan';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          uid_location:this.setQuanUid,
          quan:this.location_quan
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(() => {
            this.getDatas();
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogQuan = false
          })
      }

    }
  }
</script>