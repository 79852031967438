<!-- Header.vue -->
 
<template>
  <div id="header">
    <div style="width:20vw; height:100%; text-align:left;" @click="goBack">
      <v-icon
        style="font-size:10vw;"
      >
        mdi-arrow-left-bold
      </v-icon>
    </div>
    <div style="width:60vw; height:100%; font-size:8vw; text-align:center;">
      {{name}}
      <!-- <img src="../../assets/logos/LogiForm_logo.png" width="100%" height="50%" @click="clickLogo"/> -->
    </div>
    <div style="width:20vw; height:100%; text-align:right; padding-right:1vw; padding-top:1vw;">
      <v-icon
        style="font-size:8vw;"
        color="#2A5786"
        @click="drawer = !drawer"
      >
        mdi-menu
      </v-icon>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
      >
        <v-list
          nav
          dense
        >
          <v-list-item
            v-for="(menu, i) in cards"
            :key="i"
            link
            @click="goToMenu(menu.code)"
            style="border:1px solid; background: linear-gradient(rgb(253 253 253), rgb(243 243 243));"
          >
            <img :src="menu.icon" style="width:20%;" />
            <v-list-item-content>
              <v-list-item-title style="margin:10%; color:black; font-size:15px; overflow: visible;">{{menu.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-divider></v-divider> -->
        </v-list>
      </v-navigation-drawer>
    </div>
    
    <v-dialog
      v-model="dialogLogout"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5" style="font-weight: bold;">
          로그아웃
        </v-card-title>
        <v-card-text class="text-h7" style="font-weight: bold;">
          로그인 페이지로 돌아가시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="logout_confirm"
          >
            로그아웃
          </v-btn>
          <v-btn
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="dialogLogout = false"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet 
      v-model="user_sheet" 
    >
      <v-container
        class="text-center"
        width="100%"
        style="background:#e5e5e5;"
        scrollable
      >
        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="manageUser"
          :hidden="mgmtUser"
        >
          사용자 관리
        </v-btn>

        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="manageUser"
        >
          사용자 정보
        </v-btn>

        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="user_sheet = !user_sheet"
        >
          회사정보
        </v-btn>

        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#d6e4e4; "
          @click="dialogLogout = true"
        >
          로그아웃
        </v-btn>

      </v-container>
    </v-bottom-sheet>

    <UserInfo 
      v-bind:userInfoDialog="showDialog"
      v-model="showDialog"
      @close:dialog="val => showDialog = val"
    />
  </div>

</template>
 
<script>
import leftMenu from '@/components/main/LeftMenu'
import UserInfo from '../views/UserInfoView.vue'
var left = leftMenu

export default {
  name: 'Header',
  components: {
    UserInfo:UserInfo
  },
  props: {
    name:String
  },
  watch: {
    $route(to, from) {
      console.log('== to', to);
    }
  },
  mounted() {
    
  },
  data() {
    return {
      group: null,
      showDialog:false,
      user_sheet:false,
      mgmtUser:true,
      dialogLogout:false,

      toggle: false,
      // toggle: this.enter,
      message: "Hello, nana.",
      taskTotal: 5,
      dialog:false,
      drawer:false,
      
      cards:  [
        // { code:'HOME', name:'HOME', link: 'components/views/Home', icon:require('@/assets/icons/house.png') },
        // { code:'login', name:'로그인', link: 'components/main/LoginForm', icon:require('@/assets/icons/door-in.png') },
        { code:'WEARING', name:'입고', link: 'components/views/Wearing', icon:require('@/assets/icons/warehouse.png') },
        { code:'RELEASE', name:'출고', link: 'components/views/Release', icon:require('@/assets/icons/shipped.png') },
        { code:'STOCKMOVE', name:'재고위치조정', link: 'components/views/StockMove', icon:require('@/assets/icons/move-stock.png') },
        { code:'SEARCH_ITEM', name:'품목별조회', link: 'components/views/SearchItem', icon:require('@/assets/icons/search_item.png') },
        { code:'STOCK_MGMT', name:'재고현황', link: 'components/views/StockMgmtView', icon:require('@/assets/icons/search_location.png') },
        // { code:'SEARCH_LOCATION', name:'위치별조회', link: 'components/views/SearchLocation', icon:require('@/assets/icons/search_location.png') },
        { code:'SEARCH_LOADRATE', name:'적재율조회', link: 'components/views/SearchLoadRate', icon:require('@/assets/icons/search_rate.png') },
        { code:'SELECT_ITEM_VIEW', name:'품목관리', link: 'components/views/SelectItemView', icon:require('@/assets/icons/mgmt_item.png') },
        { code:'REGIST_LOCATION_BARCODE', name:'위치관리', link: 'components/views/RegistLocationBarcode', icon:require('@/assets/icons/mgmt_location.png') },
      ],
    };
  },

  methods : {
    clickLogo() {
      this.$router.replace('home')
    },
    goBack() {
      this.$router.back();
    },
    goToMenu(path) {
      this.$router.push(path);
    },

    showMenu() {
      if(this.toggle) {
        left.methods.leave()
      } else {
        left.methods.enter()
      }
      this.toggle = !this.toggle;
    },

    logout_alert() {
      this.dialog = true;
      // this.$store.commit('clearUserInfo')
      // this.$router.push('login')
    },

    logout_confirm() {
      this.dialogLogout = false
      this.user_sheet = false
      this.$store.commit('clearUserInfo')
      this.$router.replace('login')
    },

    logout_cancel() {
      this.dialog = false;
    },

    manageUser() {
      this.user_sheet = false
      this.showDialog = true
    },
    getUserId() {
      const cur_user_id = this.$store.getters.getUserId
      if(cur_user_id!=null && (cur_user_id == 'admin' || cur_user_id == 'root')) {
        this.mgmtUser = false
      } else {
        this.mgmtUser = true
      }
    }
  }
};
</script>