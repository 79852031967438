<template>
  <div class="content">
    <div id="barcodeScan" class="fullscreen" 
        
    >
      <StreamBarcodeReader
        @decode="onDecode"
        @error="onError"
      ></StreamBarcodeReader>
    </div>
  </div>
</template>
 
<script>
// style="position:absolute; width:600px; height:100px; top:15%; left:15%;"
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import { ImageBarcodeReader } from "vue-barcode-reader";

  export default {
    name:'scan',
    components: {
      StreamBarcodeReader:StreamBarcodeReader,
      // ImageBarcodeReader:ImageBarcodeReader
    },
    mounted() {
      var params = this.$route.params
      
      var returnView = params.returnView
      if(returnView!=null && returnView!=undefined) this.returnView = returnView

      var selectedItems = params.selectedItems
      if(selectedItems!=null && selectedItems!=undefined) this.selectedItems = selectedItems

      // if back button is pressed
      window.onpopstate = function(event) {
        console.log(event)
        // alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
      }
    },
    data () {
      return {
        showBarcode:false,
        barcodeValue:null,
        returnView:null,
        selectedItems:[]
      }
    },

    methods: {
      openScanner() {
        this.showBarcode = !this.showBarcode;
      },

      onDecode (result) {
        console.log('==== on decode: ', result)
        // this.$router.go(-1, {name:'ㅋㅋㅋㅋ'})
        this.$router.push({name:this.returnView, params: {scan_result:result, selectedItems:this.selectedItems}})
        // this.$router.back({query: {name: 'cat', age: 3}})

        // this.barcodeValue = result;
        // this.showBarcode = !this.showBarcode;
      },

      onError (result) {
        console.log('==== on Error', result)
      },
    }
  }
</script>

<style>
  .fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
</style>