<!-- Wearing.vue -->
 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            filled
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :loading="isLoading"
            :search-input.sync="search"
            
            clearable
            hide-details
            hide-selected
            item-text="search_name"
            item-value="unique_id"
            return-object
            label="품목검색"
            solo
            @click:append-outer="openScanner"
            @change="click"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
      
    <v-data-table
      v-model="selectedRecords"
      :headers="columns"
      :mobile-breakpoint="1024"
      :items="datas"
      item-key="id"
      hide-default-footer
      hide-default-header
      class="elevation-1"
      style="width:100%; height:78%; overflow:scroll;"
    >
      <template v-slot:item="row" >
        <v-toolbar
          light
          color='#65D285'
        >
          <v-checkbox
            v-model="row.item.checkbox"
            :disabled=true
          ></v-checkbox>
          <v-btn 
            icon
            x-large
            @click="copyRow(row.item)"
          >
            <v-icon>
              mdi-plus-circle
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            icon
            x-large
            @click="removeRow(row.item.id)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="mx-auto"
          :color="selectedRecords.indexOf(row.item.id)>-1?'cyan':'green'" 
          @click="rowClicked(row.item)"
          dark
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.id"
        >
          
          <v-card-text style="font-size:18px; font-weight:bold; padding: 1%;" class="d-flex justify-start row-1" >품명 
            <v-spacer></v-spacer> 
            {{row.item.item_name}} 
          </v-card-text>
          <v-card-text style="font-size:18px; font-weight:bold; padding: 1%;" class="d-flex justify-start row-2" >규격 
            <v-spacer></v-spacer> 
            {{row.item.specification}} 
          </v-card-text>
          <v-card-text style="font-size:18px; font-weight:bold; padding: 1%;" class="d-flex justify-start row-2" >수량 
          <v-spacer></v-spacer> 
          <v-edit-dialog
            :return-value.sync="row.item.quan"
            @save="save"
            @cancel="cancel"
          >
            <v-btn class="mx-1" color="#46B2E1">
              {{row.item.quan * 1}}
            </v-btn>
            <template v-slot:input>
              <v-text-field
                v-model="row.item.quan"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="margin-right: 5%;"
            @click="pageBack"
          >
            이전
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="selectLocation"
          >
            다음
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan" class="fullscreen" 
        
      >
        <StreamBarcodeReader
          @decode="onDecode"
          @error="onError"
        ></StreamBarcodeReader>
      </div>
    </v-dialog>

    <v-dialog
      persistent
      light
      v-model="dialogQuan"
      max-width="400"
    >
      <v-text-field
        v-model="numberValue"
        hide-details
        single-line
        type="number"
        autofocus="true"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogNext"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          품목을 선택해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogNext = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZero"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          수량을 확인해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogZero = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <h5>Selected: {{selectedRecords}}</h5> -->
  </div>
</template>
 
<script>
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import { ImageBarcodeReader } from "vue-barcode-reader";

  export default {
    mounted() {
      var scan_result = this.$route.params.scan_result;
      if(scan_result!=null && scan_result!=undefined) {
        this.itemCodeValue = scan_result;
      }
    },
    components: {
      StreamBarcodeReader:StreamBarcodeReader,
    },
    data () {
      return {
        max25chars: v => v.length <= 25 || 'Input too long!',
        dialogQuan:false,
        dialogNext:false,
        dialogZero:false,
        numberValue:0,
        searchModel:null,
        search: null,
        searchItems:[],
        isLoading:false,
        dialogScan:false,
        selectedRecords:[],
        selectedObject:[],
        showBarcode:false,
        itemCodeValue:null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        columns: [
          { text: '품명', sortable: false, value: 'item_name', align:'center'},
          { text: '규격', value: 'specification', align:'center' },
          { text: '수량', value: 'quan', align:'center' },
          { text: '', value: '', align:'center' },
        ],
        datas: [],
      }
    },

    watch: {
      searchModel (val) {

      },
      search (val) {
        if(val == null || val.length < 1) return;
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          item_name:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== 2222', res)
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      }
    },

    methods: {
      openScanner() {
        this.dialogScan = true
      },

      click(value) {
        if(value == null) return;
        console.log('==== @@@@@', this.datas);
        const len = this.datas.length
        var newRec = {
          // id:this.searchModel.unique_id,
          id:len,
          unique_id:this.searchModel.unique_id,
          item_name:this.searchModel.item_name,
          specification:this.searchModel.specification,
          quan:0,
          checkbox:false
        }

        var check = false;
        this.datas.forEach((data) => {
          var uid = data.unique_id
          var _uid = this.searchModel.unique_id
          if(uid == _uid) check = true
        })

        console.log('===== check', check)

        if(!check) {
          this.datas.push(newRec)
        }

        // search clear
        this.search = null
        this.searchModel = null
        this.searchItems = []
      },

      onDecode (result) {
        alert(result)
        console.log('==== on decode: ', result)
        this.itemCodeValue = result;
        this.showBarcode = !this.showBarcode;
      },

      onError (result) {
        console.log('==== on Error', result)
      },

      selectLocation() {
        this.updateSelectedObject();
        if(this.selectedObject == null || this.selectedObject.length<1) {
          this.dialogNext = true;
          return;
        } else {
          for(var i=0; i<this.selectedObject.length; i++) {
            var obj = this.selectedObject[i]
            var quan = obj.quan
            if(quan<1) {
              this.dialogZero = true;
              return
            }
          }
        }
        this.$router.push({name:'LOCATION', params: {selectedItems:this.selectedObject}})
      },

      pageBack() {
        this.$router.push('home')
      },

      click_row(obj, row) {
        console.log('==== click_row obj', obj)
        console.log('==== click_row row', row)
        if(this.selectedRecords==null || this.selectedRecords.length<1) {
          this.selectedRecords.push(obj);
        } else {
          var obj_id = obj.unique_id
          var check = false;
          for(var i in this.selectedRecords) {
              var rec = this.selectedRecords[i]
              var id = rec.unique_id
              if(id == obj_id) {
                check = true
                this.selectedRecords.splice(i, 1)
                break;
              }
          }

          if(!check) {
            this.selectedRecords.push(obj);
          }
        }
      },

      rowClass(obj) {
        // var names = values.map(function(value){ return value.name })
        var id = obj.unique_id
        var is_selected = false

        this.selectedRecords.map(function(rec) {
          var _id = rec.unique_id
          // console.log('==========@@@@', id + ' : ' + _id)
          if(id == _id) {
            is_selected = true
          }
        })

        // console.log('==========@@@@ is_selected', is_selected)
        // if(is_selected) {
        //   const rowClass = 'vvvvvvv'
        //   console.log('==========@@@@ rowClass', rowClass)
        //   return rowClass
        // }
        // const rowClass = 'v-data-table__custom_selected_blue '
        const rowClass = is_selected == true ? 'v-data-table__custom_selected_blue ' : ''
        return rowClass
      },

      changeQaun(item) {
        this.dialogQuan = true
        // item.quan = 10;
      },

      save() {

      },

      cancel() {

      },

      removeRow(id) {
        for(var i in this.datas) {
          var data = this.datas[i]
          var uid = data.id
          if(uid == id) {
            this.datas.splice(i, 1)
          }
        }
      },
      copyRow(item) {
        var id = this.datas.length;
        var len = 0;
        var obj = {};

        this.datas.forEach((data, i) => {
          var uid = data.unique_id
          if(uid == item.unique_id) {
            len = len + i;
            obj = {
              id:id,
              unique_id:data.unique_id,
              item_name:data.item_name,
              specification:data.specification,
              quan:0,
              checkbox:false
            }
          }
        })

        // this.datas.push(obj)
        this.datas.splice(len, 0, obj)
      },

      clickCheckBox(item) {
        console.log('==== clickCheckBox', item.checkbox);
        this.rowClicked(item)
      },

      rowClicked(row) {
        console.log('==== rowClicked', row);
        row.checkbox = !row.checkbox // card 클릭시 checkbox change
        // this.toggleSelection(row.unique_id);
        this.toggleSelection(row.id);
      },
      toggleSelection(keyID) {
        if (this.selectedRecords.includes(keyID)) {
          this.selectedRecords = this.selectedRecords.filter(
            selectedKeyID => selectedKeyID !== keyID
          );
        } else {
          this.selectedRecords.push(keyID);
        }
      },

      updateSelectedObject() {
        this.selectedObject.splice(0, this.selectedObject.length)
        for(var i in this.selectedRecords) {
          var _id = this.selectedRecords[i];
          for(var j in this.datas) {
            var o = this.datas[j]
            var __id = o.id
            if(_id == __id) {
              this.selectedObject.push(o)
            }
          }
        }
      },

    }
  }
</script>

<style scoped>
  .v-data-table__selected {
    background:#7299c9
  }
  .v-data-table__custom_selected_blue {
    background:#7299c9
  }
  .table >>> th {
    font-size: 1.8rem !important; 
  }
</style>