<template>
  <div id="login">
    <div id="loginLogo">
      <img src="../../assets/logos/LogiForm_logo.png" width="50%" height="40%" />
      <div style="color:#4183ff; font-size:18px; padding-top:5%;">
        FAST / SAFE / EASY
      </div>
    </div>
    <div id="loginForm" ref="loginForm">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        style="padding:5%; width:100%; height:100%; backgroundColor:white; border-radius: 5%;"
      >
        <v-text-field
          v-model="cp_code"
          label="회사코드"
          clearable
          required
          style="width:100%; color:#264b8c;"
        ></v-text-field>

        <v-text-field
          v-model="user_id"
          label="아이디"
          clearable
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="비밀번호"
          clearable
          required
          :type="'password'"
        ></v-text-field>

        <div style="text-align:center; margin-top:10%;">
          <v-btn
            text
            class="mr-1"
            style="width:50%; background-color: #4183ff; color:white; font-size:1.5em; height:2em;"
            @click="checkCpCode"
          >
            로그인
          </v-btn>
        </div>
			  <div id="errMsg" style="color:red; text-align:center; padding-top:10%;" v-if="loginError">
          로그인 정보를 확인해주세요
        </div>
      </v-form>
    </div>
    <div id="foot_img">
      <img :src="require('@/assets/icons/login_back.png')" style="width:70%; height:80%;" />
    </div>
  </div>
</template>
 
<script>
import '@/components/base/Base';
import Base from '@/components/base/Base.vue'

export default {
  mounted() {
    this.getUserSetting();
  },
  
  data() {
    return {
      valid:true,
      uid_company:-1,
      cp_code:null,
      cp_name:null,
      user_id:null,
      password:null,
      loginError:false,
    };
  },

  methods : {
    checkCpCode() {
      if(this.cp_code == null || this.cp_code.length<1) {
        this.loginError = true;
        return;
      }

      var obj = {
        cp_code:this.cp_code
      };
      var url = this.$vBACKEND_URL + '/index/login.do?method=checkCpCode';
      var params = '';

      for(var key in obj) {
        var val = obj[key]
        params += '&' + key + '=' + val;
      }

      url += params;

      fetch(url)
      .then(res => res.clone().json())
      .then(res => {
        var result = res.result; // uid_company
        this.uid_company = result;
        this.login();
      })
    },
    login() {
      if(this.uid_company == null || this.uid_company < 1
        || this.user_id == null || this.user_id.length< 1
        || this.password == null || this.password.length< 1) {
          this.loginError = true;
          return;
      }

      var p_obj = {
        uid_company:this.uid_company,
        user_id:this.user_id,
        password:this.password,
        cp_code:this.cp_code,
        cp_name:this.cp_name
      }

      var url = this.$vBACKEND_URL + '/index/login.do?method=mobileLogin';
      var params = '';

      for(var key in p_obj) {
        var val = p_obj[key]
        params += '&' + key + '=' + val;
      }
      
      url += params;

      fetch(url)
      .then(res => res.json())
      .then(res => {
        var result = res.result;
        if(result!=null && result.length>0) {
          var loginData = result[0];

          this.setLoginUserInfo(loginData, p_obj)
          this.getMenuSetting();

          this.$router.replace('home');
        } else {
          this.loginError = true;
        }
      })
    },

    setLoginUserInfo(loginData, obj) {
      // 로그인 성공시 로그인 데이터 저장 + 로그아웃 시에 회사코드+아이디 저장
      // uid_company, user_id, user_uid, user_id, user_name, 
      // cp_code, cp_name
      obj.uid_company = loginData.uid_company
      obj.user_id = loginData.user_id
      obj.user_uid = loginData.unique_id
      obj.user_name = loginData.user_name
      obj.cp_code = loginData.cp_code
      obj.cp_name = loginData.cp_name
      obj.codeType = loginData.code_type
      this.$store.commit('setLoginDatas', obj);
    },

    loginErrorOkay() {
      this.loginError = !this.loginError;
    },

    getCustomers() {
      // var me = this
      // var url = this.$vBACKEND_URL + '/index/main.do?method=getCustomers';
      // fetch(url)
      // .then((response) => {
      //   return response.json();
      // })
      // .then(function(json) {
      //   let result = json.datas;
      //   if(result!=null && result.length>0) {
      //     me.customers = result;
      //   }
      //   me.getUserSetting();
      // });
    },

    getUserSetting() {
      const login_info = this.$store.getters.getLoginDatas;
      this.user_id = login_info.user_id
      this.cp_code = login_info.cp_code
    },

    setSaveId() {
      this.save_id = !this.save_id;
      this.$store.commit('setSaveId', this.save_id);
    },

    setSaveLogin() {
      this.save_login = !this.save_login;
      this.$store.commit('setSaveLogin', this.save_login);
    },

    changeCustomer() {
      console.log('==== changeCustomer', this.selectedCustomer)
      this.uid_company = this.selectedCustomer.id
      this.cp_code = this.selectedCustomer.cp_code
      this.cp_name = this.selectedCustomer.cp_name
    },

    getMenuSetting() {
      console.log('==== getMenuSetting')

      var url = this.$vBACKEND_URL + '/index/main.do?method=getMenuList';
      var me = this

      fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(function(json) {
        let result = json.datas;
        if(result!=null && result.length>0) {
          me.$store.commit('setMenuList', result);
        }
      });
    }
  },
};
</script>

<style>
  input::placeholder {
    font-style: italic;
    font-weight: normal;
  }
  input {
    font-weight: bold;
  }
</style>