<!-- Footer.vue -->
 
<template>
  <div id="footer">
    Made By 한보일렉트(주)
  </div>

</template>
 
<script>
export default {
  name:"Footer"
};
</script>