<!-- LoginForm.vue -->
<!-- v-bind:style="{width:'100%', height:'5%', backgroundColor:'orange', 'text-align':'center'}" -->

<template>
  <div id="login">
    <div id="loginLogo">
      <img src="../../assets/logos/LogiForm_logo.png" width="50%" height="40%" />
      <div style="color:#4183ff; font-size:18px; padding-top:5%;">
        FAST / SAFE / EASY
      </div>
    </div>
    <div id="loginForm" ref="loginForm">
      <div class="card align-middle" style="width: 30rem; border-radius:20px; align-self:center;">  <!-- height: 23rem;  -->
				<div class="card-body">
			     <!--<form class="form-signin">  action="/login.do?method=login" method="POST" -->
			        <!-- <h5 class="form-signin-heading">(주)엠시스아이씨티 WMS </h5>  -->
              <v-combobox
                label="업체명"
                :items="customers"
                item-text="cp_name"
                v-model="selectedCustomer"
                v-on:input="changeCustomer"
                dense
                filled
                outlined
                persistent-hint
              ></v-combobox>
			        <input type="text" id="user_id" name="user_id" v-model="login_id" class="form-control" placeholder="로그인 아이디" required autofocus><br>
			        <input type="password" id="password" name="password" class="form-control" v-model="login_pw" placeholder="패스워드" required><br>
			        <div class="checkbox">
			          <label>
			            <input type="checkbox" value="save-login-id" v-model="save_id" @click="setSaveId"> 아이디 저장
			          </label>
                <!-- <div></div>
			          <label>
			            <input type="checkbox" value="save-login-status" v-model="save_login" @click="setSaveLogin"> 로그인 유지
			          </label> -->
			        </div>
			        <button id="btn-Yes" class="btn btn-lg btn-primary btn-block" @click="login">로 그 인</button>
			      <!--</form>-->
				</div>
			  <div id="errMsg" style="color:red; text-align:center;" v-if="loginError">
          로그인 정보를 확인해주세요
        </div>
      </div>
      <!-- <v-alert
        text
        type="error"
        icon="mdi-cloud-alert"
        v-if="is_alert"
        style="position:absolute; background-color:red;"
      >
        <h3 class="text-h5">
          알림
        </h3>
        <v-divider
          class="my-4 info"
          style="opacity: 0.22"
        ></v-divider>
        <v-row
          align="center"
          no-gutters
        >
          <v-col class="grow">
            로그인 정보를 확인해주세요
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-btn
              color="info"
              outlined
              @click="loginErrorOkay"
            >
              확인
            </v-btn>
          </v-col>
        </v-row>
      </v-alert> -->
    </div>

    <div id="footer" >
      
    </div>
  </div>

</template>
 
<script>
import '@/components/base/Base';
import Base from '@/components/base/Base.vue'

export default {
  mounted() {
    this.getCustomers();
    
  },
  
  data() {
    return {
      customers:[],
      selectedCustomer:null,
      uid_company:-1,
      cp_code:null,
      cp_name:null,
      login_id:null,
      login_pw:null,
      is_alert:false,
      loginError:false,

      save_id:true,
      save_login:false
    };
  },

  methods : {
    login() {
      // this.$router.push('home');

      if(this.uid_company == null || this.uid_company < 1
        || this.login_id == null || this.login_id.length< 1
        || this.login_pw == null || this.login_pw.length< 1) {
          this.loginError = true;
          return;
      }

      var p_obj = {
        uid_company:this.uid_company,
        user_id:this.login_id,
        login_id:this.login_id,
        password:this.login_pw,
        cp_code:this.cp_code,
        cp_name:this.cp_name
      }

      var url = this.$vBACKEND_URL + '/index/login.do?method=mobileLogin';
      var params = '';

      for(var key in p_obj) {
        var val = p_obj[key]
        params += '&' + key + '=' + val;
      }
      
      url += params;

      fetch(url)
      .then(res => res.json())
      .then(res => {
        var result = res.result;
        if(result!=null && result.length>0) {
          var loginData = result[0];
          this.setLoginUserInfo(loginData, p_obj)
          this.getMenuSetting();
          this.$router.replace('home');
          // this.$router.push('home');
        } else {
          this.loginError = true;
        }
      })
    },

    setLoginUserInfo(loginData, obj) {
      obj.user_uid = loginData.unique_id
      obj.user_id = loginData.user_id
      obj.user_name = loginData.user_name
      this.$store.commit('setLoginDatas', obj, loginData);
    },

    loginErrorOkay() {
      this.loginError = !this.loginError;
    },

    getCustomers() {
      var me = this
      var url = this.$vBACKEND_URL + '/index/main.do?method=getCustomers';
      fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(function(json) {
        let result = json.datas;
        if(result!=null && result.length>0) {
          me.customers = result;
        }
        me.getUserSetting();
      });
    },

    getUserSetting() {
      const login_info = this.$store.getters.getLoginDatas;

      let is_saveId = login_info.is_saveId
      if(is_saveId) {
        this.login_id = login_info.login_id
        this.uid_company = login_info.uid_company
        this.save_id = login_info.is_saveId
        this.save_login = login_info.is_saveLogin

        var uid_customer = login_info.uid_company

        for(var i=0; i<this.customers.length; i++) {
          var c = this.customers[i];
          var id = c.unique_id
          if(id == uid_customer) {
            this.selectedCustomer = c
          }
        }
      }
    },

    setSaveId() {
      this.save_id = !this.save_id;
      this.$store.commit('setSaveId', this.save_id);
    },

    setSaveLogin() {
      this.save_login = !this.save_login;
      this.$store.commit('setSaveLogin', this.save_login);
    },

    changeCustomer() {
      console.log('==== changeCustomer', this.selectedCustomer)
      this.uid_company = this.selectedCustomer.id
      this.cp_code = this.selectedCustomer.cp_code
      this.cp_name = this.selectedCustomer.cp_name
    },

    getMenuSetting() {
      console.log('==== getMenuSetting')

      var url = this.$vBACKEND_URL + '/index/main.do?method=getMenuList';
      var me = this

      fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(function(json) {
        let result = json.datas;
        if(result!=null && result.length>0) {
          me.$store.commit('setMenuList', result);
        }
      });
    }
  },
};
</script>

<style>
  input::placeholder {
    font-style: italic;
    font-weight: normal;
  }
  input {
    font-weight: bold;
  }
</style>