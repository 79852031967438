<template>
  <div class="content" style="height:100%; min-height:100%; position:fixed;">
    <v-autocomplete
      :append-outer-icon="'mdi-barcode-scan'"
      clear-icon="mdi-close-circle"
      type="text"
      v-model="searchModel"
      :items="searchItems"
      :search-input.sync="search"
            
      clearable
      hide-details
      hide-selected
      hide-no-data
      item-text="search_name"
      item-value="unique_id"
      return-object
      label="품번/품명 검색"
      @click:append-outer="clickScanType($store.getters.getCodeType)"
      @change="click"
      @click:clear="clear"
      @click="search_void"
      style="padding:5%; padding-bottom: 0;"
    >
      <!-- <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          color="blue-grey"
          class="white--text"
          v-on="on"
        >
          <v-icon left>
            mdi-format-list-bulleted-square
          </v-icon>
          <span v-text="item.search_name"></span>
        </v-chip>
      </template> -->
    </v-autocomplete>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="padding:5%; overflow-y: scroll; height: 70%;"
    >
      <v-text-field
        v-model="item_code"
        label="품번"
        outlined
        readonly
      ></v-text-field>

      <v-text-field
        v-model="item_name"
        label="품명"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="specification"
        label="규격"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="detail_info"
        label="상세사양"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="unit_code"
        label="단위"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="safe_quan"
        label="안전재고수량"
        outlined
      ></v-text-field>

    </v-form>
      
    <div style="text-align:center; margin-top:5%;">
      <v-row>
        <v-col cols="6">
          <v-btn
            text 
            @click="() => dialogEdit = true"
            class="mr-1"
            style="width:50%; background-color: rgb(103 219 122); color:white;"
          >
            수정
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            text
            @click="() => dialogRemove = true"
            class="mr-1"
            style="width:50%; background-color: rgb(219 103 103); color:white;"
          >
            삭제
          </v-btn>
        </v-col>
      </v-row>
    </div>
    
    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet
      v-model="dialogEdit"
      max-width="600"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="text-align:center; font-size:4vw; font-weight:bold;">
          해당 항목을 수정하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="edit_item"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            확인
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="() => dialogEdit = false"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    
    <v-bottom-sheet
      v-model="dialogRemove"
      max-width="600"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="text-align:center; font-size:4vw; font-weight:bold;">
          해당 항목을 삭제하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="remove_item"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            확인
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="() => dialogRemove = false"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    
  <v-dialog
    v-model="dialogScan"
    max-width="400"
  >
    <div id="barcodeScan">
    </div>
  </v-dialog>

    <v-dialog
      v-model="dialogRazer"
      width="50%"
      max-width="1000px"
      :scrollable="false"
    >
      <div style="width:100%; height:80%; background:white; padding:2%;">
        <v-text-field
          v-model="razerValue"
          label="검색"
          autofocus
        >
        </v-text-field>
      </div>

      <v-snackbar
          v-model="snackRazer"
          :timeout="snackTimeRazer"
          :color="snackColorRazer"
      >
          {{ snackTextRazer }}
      </v-snackbar>
    </v-dialog>

  <v-dialog
    v-model="isLoading"
    persistent
    width="300"
  >
  <template>
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        품목을 등록중입니다.<br/>잠시만 기다려주세요.
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </template>
  </v-dialog>

  <v-bottom-sheet
    v-model="scan_sheet"
  >
    <div style="width:100%; height:100%; background:white;">
      <v-container
        class="text-center"
        width="100%"
        scrollable
      >
        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
          @click="clickScanType('BARCODE')"
        >
          바코드
        </v-btn>
        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
          @click="clickScanType('QRCODE')"
        >
          QR코드
        </v-btn>
        <v-btn
          class="mt-6"
          text
          style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
          @click="clickScanType('RAZER')"
        >
          레이저 스캐너
        </v-btn>
      </v-container>
    </div>
  </v-bottom-sheet>

  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      
    },
    components: {
      // StreamBarcodeReader:StreamBarcodeReader,
    },

    watch: {
      searchModel (val) {

      },
      search (val) {
        if(val == null || val.length < 1) return;
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:val
          // item_name:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      dialogScan() {
        if(!this.dialogScan) {
          const isScanning = this.scanner.isScanning;
          if(isScanning) this.scanner.stop();
        }
      },

      razerValue() {
        if(this.razerValue!=null && this.razerValue.length>0) {
          setTimeout(function() {
            this.searchFn(this.razerValue);
          }.bind(this), 1500);
        }
      }
    },

    data () {
      return {
        valid: true,

        uid_item:null,
        item_code:null,
        item_name:null,
        specification:null,
        detail_info:null,
        unit_code:null,
        safe_quan:null,

        is_duplicate:false,

        isLoading:false,

        dialogScan:false,
        dialogAlaram:false,
        dialogRemove:false,
        dialogEdit:false,
        alaramText:'',
        
        searchModel:null,
        search: null,
        searchItems:[],

        scanner:null,
        qrbox:null,
        fps:null,

        scan_sheet:false,
        scanType:'',
        dialogRazer:false,
        razerValue:'',

        snack:false,
        snackTime:3000,
        snackColor:'',
        snackText:'',

        snackRazer:false,
        snackTimeRazer:3000,
        snackColorRazer:'',
        snackTextRazer:'',
      }
    },

    methods: {
      search_void() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },

      clickScanType(type) {
        console.log('=== clickScanType type', type)
        this.scan_sheet = false;
        this.scanType = type;

        switch(type) {
          case 'BARCODE':
          case 'QRCODE':
            this.openScanner(type);
            break;
          case 'RAZER':
            this.dialogRazer = true
            break;
        }
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
            // this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 50} }, this.onScanSuccess);

            
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      readRazerSuccess(value) {
        this.searchFn(value);
        // this.dialogRazer = false;
        // this.razerValue = '';
      },

      searchFn(val) {
        if(val == null || val.length < 1) return;
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          barcode:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0];
              this.click(this.searchItems[0])
            } else {
              switch(this.scanType) {
                case 'RAZER':
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snackRazer = true
                  this.snackTimeRazer = 5000
                  this.snackColorRazer = 'error'
                  this.snackTextRazer = '데이터가 존재하지 않습니다.'
                  break;
                default:
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snack = true
                  this.snackTime = 5000
                  this.snackColor = 'error'
                  this.snackText = '데이터가 존재하지 않습니다.'
                  break;
              }
            }
            this.razerValue = '';
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      pageBack() {
        this.$router.push('home')
      },

      IsDuplicateUpdate() {
        this.is_duplicate = false
      },
      
      checkDuplicate() {
        if(this.item_code == null || this.item_code.length < 1) {
          this.dialogAlaram = true
          this.alaramText = '품번을 확인해주세요'
          return
        }

        var url = this.$vBACKEND_URL + '/item.do?method=checkDuplicateItemCode'
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          item_code:this.item_code
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            var result = res.result
            if(result) {
              this.dialogAlaram = true
              this.alaramText = '사용 가능한 품번입니다.'
              this.is_duplicate = true
            } else {
              this.dialogAlaram = true
              this.alaramText = '이미 사용중인 품번입니다.'
              this.is_duplicate = false
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally()
      },

      edit_item() {

        if(this.uid_item==null || this.uid_item<1) {
          this.dialogAlaram = true
          this.alaramText = '품목을 선택해주세요'

          this.dialogEdit = false
          return
        }

        var url = this.$vBACKEND_URL + '/item.do?method=editItem';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          uid_item:this.uid_item,
          item_name:this.item_name,
          specification:this.specification,
          detail_info:this.detail_info,
          unit_code:this.unit_code,
          safe_quan:this.safe_quan
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => {
            this.dialogAlaram = true
            this.alaramText = '수정완료'

            this.resetDatas()
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogEdit = false
          })
      },

      remove_item() {
        console.log('=== remove item', this.uid_item)
        if(this.uid_item==null || this.uid_item<1) {
          this.dialogAlaram = true
          this.alaramText = '품목을 선택해주세요'

          this.dialogRemove = false
          return
        }

        var url = this.$vBACKEND_URL + '/item.do?method=removeItem';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          uid_item:this.uid_item
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => {
            this.dialogAlaram = true
            this.alaramText = '삭제완료'  

            this.resetDatas()
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogRemove = false
          })
      },

      resetDatas() {
        this.uid_item = null
        this.item_code = null
        this.item_name = null
        this.specification = null
        this.detail_info = null
        this.unit_code = null
        this.safe_quan = null

        this.searchModel = null
        this.searchItems = []
      },

      registEvent() {
        if(!this.is_duplicate) {
          this.dialogAlaram = true
          this.alaramText = '중복확인을 해주세요'
          return
        }

        if(this.item_code==null || this.item_code.length<1) {
          this.dialogAlaram = true
          this.alaramText = '품번을 확인 해주세요'
          return
        }

        if(this.item_name==null || this.item_name.length<1) {
          this.dialogAlaram = true
          this.alaramText = '품명을 확인 해주세요'
          return
        }

        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=registItem'
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          
          item_code:this.item_code,
          item_name:this.item_name,
          specification:this.specification,
          detail_info:this.detail_info,
          unit_code:this.unit_code,
          safe_quan:this.safe_quan,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            var result = res.result
            if(result) {
              this.dialogAlaram = true
              this.alaramText = '등록성공'

              this.refreshData();
            } else {
              this.dialogAlaram = true
              this.alaramText = '등록실패'
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      refreshData() {
        this.item_code = null
        this.item_name = null
        this.specification = null
        this.detail_info = null
        this.unit_code = null
        this.safe_quan = null
        this.is_duplicate = false
      },

      download_event() {
        console.log('download_event')
      },

      upload_event() {
        console.log('upload_event')
      },

      barcode_down_event() {
        console.log('barcode_down_event')
      },

      click(value) {
        if(value == null) {
          this.search = null
          this.searchModel = null
          this.searchItems = []
          return;
        }
        console.log('==this.searchModel', this.searchModel)

        this.uid_item = this.searchModel.unique_id
        this.item_code = this.searchModel.item_code
        this.item_name = this.searchModel.item_name
        this.specification = this.searchModel.specification
        this.detail_info = this.searchModel.detail_info
        this.unit_code = this.searchModel.unit_code
        this.safe_quan = this.searchModel.safe_quan

        // search clear
        this.search = null
        this.searchModel = null
        this.searchItems = []
      },

      clear() {
        this.resetDatas();
      }

    }
  }
</script>