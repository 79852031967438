<!-- Wearing.vue --> 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"

            clearable
            hide-details
            hide-selected
            hide-no-data
            return-object

            item-text="search_name"
            item-value="unique_id"

            label="품번/품명 검색"
            @click:append-outer="clickScanType($store.getters.getCodeType)"
            @change="click"
            @click:clear="clear"
            @click="search_void"
            :autofocus="true"
            @focus="$event.target.select()"
            ref="input_searchItem"
            menu-props="{'closeOnContentClick': true}"
            @input="inputChanged"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      v-model="selectedRecords"
      :headers="columns"
      :mobile-breakpoint="1024"
      :items="datas"
      item-key="id"
      hide-default-footer
      hide-default-header
      no-data-text=""
      class="elevation-1"
      style="width:100%; height:78%; overflow:scroll;"
      disable-pagination
    >
      <template v-slot:item="row" >
        <v-toolbar
          light
          color='#cddaed'
        >
          <v-checkbox
            v-model="row.item.checkbox"
            :disabled=true
          ></v-checkbox>
          <v-btn 
            icon
            x-large
            @click="copyRow(row.item)"
          >
            <v-icon>
              mdi-plus-circle
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            icon
            x-large
            @click="removeRow(row.item.id)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="mx-auto"
          :color="selectedRecords.indexOf(row.item.id)>-1?'#264b8c':'white'" 
          @click="rowClicked(row.item)"
          
          dark
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.id"
          ref="cardComponent"
        >
          <v-card-title
            :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-code-click':'card-text-item-code'" >
            {{row.item.item_code}} 
          </v-card-title>
          <v-card-subtitle
             :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-name-click':'card-text-item-name'" >
            {{row.item.lotno}}<br>
            {{row.item.item_name}}<br>
            {{row.item.specification}}<br>
            {{row.item.detail_info}}
          </v-card-subtitle>
          <v-card-text style="font-size:20px; font-weight:bold;" class="d-flex justify-start row-2" >
          <v-spacer></v-spacer> 
          <v-btn class="mx-1" color="#46B2E1" style="font-size:1.2em;"
            @click="openDialogLotno(row.item)"
            @click.stop
          >
            {{row.item.lotno}}
          </v-btn>
          <v-btn class="mx-1" color="#46B2E1" style="font-size:1.2em;"
            @click="openDialog(row.item)"
            @click.stop
          >
            {{row.item.quan}}
          </v-btn>
          
        </v-card-text>
        </v-card>

      </template>
    </v-data-table>

  <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="width:40%; font-size:25px;"
            @click="openWearing"
          >
            입고
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-bottom-sheet
      v-model="dialogWearing"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">입고위치지정</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  label="위치검색"
                  :append-outer-icon="'mdi-barcode-scan'"
                  @click:append-outer="openScannerWearing($store.getters.getCodeType)"

                  v-model="locationModel"
                  :items="locationItems"
                  :search-input.sync="searchLocation"

                  clearable
                  item-text="bin_code"
                  item-value="bin_code"
                  return-object
                  hide-details
                  hide-selected
                  @change="clickLocation"
                  @click="searchLocation_void"
                  @keydown.enter="execWearing"
                  menu-props="{'closeOnContentClick': true}"
                  @input="inputChanged2"
                  ref="locationModel"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="execWearing"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            입고
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogWearing = false"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    
    <v-dialog v-model="dialog_quan" max-width="500px">
      <v-card>
        <v-card-title>
          입고 수량 수정
        </v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="dialogValue" 
            label="입고수량" 
            :autofocus="true"
            @keydown.enter="confirmDialog"
            :rules="[quan_rule]"
            type="number"
            @focus="$event.target.select()"
            @click.stop
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="confirmDialog">Confirm</v-btn>
          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_lotno" max-width="500px">
      <v-card>
        <v-card-title>
          Lotno 입력
        </v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="dialogValueLotno" 
            label="Lot-no" 
            :autofocus="true"
            @keydown.enter="confirmDialogLotno"
            @focus="$event.target.select()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="confirmDialogLotno">Confirm</v-btn>
          <v-btn color="secondary" @click="closeDialogLotno">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogRazer"
      width="50%"
      max-width="1000px"
      :scrollable="false"
    >
      <div style="width:100%; height:80%; background:white; padding:2%;">
        <v-text-field
          v-model="razerValue"
          label="검색"
          autofocus
        >
        </v-text-field>
      </div>

      <v-snackbar
          v-model="snackRazer"
          :timeout="snackTimeRazer"
          :color="snackColorRazer"
      >
          {{ snackTextRazer }}
      </v-snackbar>
    </v-dialog>

    <v-dialog
      persistent
      light
      v-model="dialogQuan"
      max-width="400"
    >
      <v-text-field
        v-model="numberValue"
        hide-details
        single-line
        type="number"
        :autofocus="true"
        @focus="$event.target.select()"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogNext"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          품목을 선택해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogNext = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZero"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          수량을 확인해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogZero = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <h5>Selected: {{selectedRecords}}</h5> -->

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet
      v-model="scan_sheet"
    >
      <div style="width:100%; height:100%; background:white;">
        <v-container
          class="text-center"
          width="100%"
          scrollable
        >
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('BARCODE')"
          >
            바코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('QRCODE')"
          >
            QR코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('RAZER')"
          >
            레이저 스캐너
          </v-btn>
        </v-container>
      </div>
    </v-bottom-sheet>

    <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
    >
        {{ snackText }}
    </v-snackbar>

  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      var scan_result = this.$route.params.scan_result;
      if(scan_result!=null && scan_result!=undefined) {
        this.itemCodeValue = scan_result;
      }
    },
    components: {
      
    },
    data () {
      return {
        max25chars: v => v.length <= 25 || 'Input too long!',
        dialogQuan:false,
        dialogNext:false,
        dialogZero:false,
        dialogWearing:false,
        numberValue:0,
        searchModel:null,
        search: null,
        searchItems:[],
        isLoading:false,
        dialogScan:false,
        selectedRecords:[],
        selectedObject:[],
        showBarcode:false,
        itemCodeValue:null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        columns: [
          { text: 'LOTNO', sortable: false, value: 'lotno', align:'center'},
          { text: '품명', sortable: false, value: 'item_name', align:'center'},
          { text: '규격', value: 'specification', align:'center' },
          { text: '수량', value: 'quan', align:'center' },
          { text: '', value: '', align:'center' },
        ],

        quan_rule: v => {
          v = v * 1
          if(v < 1) {
            return '1개 이상 입력해주세요.'
          }
          else{
            return true;
          }
        },
        datas: [],

        scanner:null,
        qrbox:null,
        fps:null,

        locationModel:null,
        searchLocation:null,
        locationItems:[],
        selectedBin:null,

        dialogAlaram:false,
        alaramText:null,

        scan_sheet:false,
        scanType:'',
        dialogRazer:false,
        razerValue:'',

        snack:false,
        snackTime:3000,
        snackColor:'',
        snackText:'',

        snackRazer:false,
        snackTimeRazer:3000,
        snackColorRazer:'',
        snackTextRazer:'',

        selectedItem:null,
        dialog_quan:false,
        dialogValue:0,
        dialog_lotno:false,
        dialogValueLotno:'',
        input_searchItem:null,
      }
    },
    props:{

    },
    watch: {
      searchModel (val) {

      },
      search(val) {
        if(val == null || val.length < 1) return;
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:val
          // item_name:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params; 

        console.log("readItem: " + url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== 2222', res)
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))

      },

      searchLocation(val) {
        if(val == null || val.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },

      dialogScan() {
        if(!this.dialogScan) {
          const isScanning = this.scanner.isScanning;
          if(isScanning) this.scanner.stop();
        }
      },

      razerValue() {
        if(this.razerValue!=null && this.razerValue.length>0) {
          setTimeout(function() {
            this.searchFn(this.razerValue);
          }.bind(this), 1500);
        }
      }
    },

    methods: {
      inputChanged() {
          //↓ For clear v-menu slot
          this.$refs.input_searchItem.blur()
          setTimeout(() => {
              this.$refs.input_searchItem.focus()
          }, 500)
      },
      inputChanged2() {
          //↓ For clear v-menu slot
          this.$refs.locationModel.blur()
          setTimeout(() => {
              this.$refs.locationModel.focus()
          }, 500)
      },
      resetDatas() {
        this.datas = [];
      },
      clear() {
        this.resetDatas();
      },
      searchLocation_void() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      },

      search_void() {
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params; 

        console.log("readItem: " + url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== 2222', res)
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

      openDialog(item) {
        this.selectedItem = item;
        this.dialogValue = item.quan;
        this.dialog_quan = true;
      },
      confirmDialog() {
        console.log(this.selectedItem);
        this.selectedItem.quan = this.dialogValue;
        this.closeDialog();
        this.clickCheckBox(this.selectedItem);
      },
      closeDialog() {
        this.dialog_quan = false;
        // if(this.searchModel.focus!==null){
        //   this.searchModel.focus();
        // }
        this.$refs.input_searchItem.focus();
      },

      openDialogLotno(item) {
        this.selectedItem = item;
        this.dialogValueLotno = item.lotno;
        this.dialog_lotno = true;
      },
      confirmDialogLotno() {
        console.log(this.selectedItem); 
        this.selectedItem.lotno = this.dialogValueLotno;
        this.closeDialogLotno();
      },
      closeDialogLotno() {
        this.dialog_lotno = false;
        this.openDialog(this.datas[this.datas.length-1]);
      },

      clickScanType(type) {
        console.log('=== clickScanType type', type)
        this.scan_sheet = false;
        this.scanType = type;

        switch(type) {
          case 'BARCODE':
          case 'QRCODE':
            this.openScanner(type);
            break;
          case 'RAZER':
            this.dialogRazer = true
            break;
        }
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
            // this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 50} }, this.onScanSuccess);            
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      readRazerSuccess(value) {
        console.log('==@@@@@ readRazerSuccess', value)
        this.searchFn(value);
        // this.dialogRazer = false;
        // this.razerValue = '';
      },

      openScannerWearing(type) {
        this.setIsScanWearing()
         .then(() => {
           this.startScanWearing(type)
         })
      },
      setIsScanWearing() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScanWearing(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        console.log("type:"+type);
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccessWearing);
        });
      },
      onScanSuccessWearing(qrCodeMessage) {
          //if(qrCodeMessage!=null) qrCodeMessage = qrCodeMessage.replace('-', '');
          this.searchFnWearing(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanErrorWearing(errorMessage) {
          console.log(errorMessage);
      },

      searchFn(val) {
        if(val == null || val.length < 1) return;
        this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          barcode:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0];
              this.click(this.searchItems[0])
            } else {
              switch(this.scanType) {
                case 'RAZER':
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snackRazer = true
                  this.snackTimeRazer = 5000
                  this.snackColorRazer = 'error'
                  this.snackTextRazer = '데이터가 존재하지 않습니다.'
                  break;
                default:
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snack = true
                  this.snackTime = 5000
                  this.snackColor = 'error'
                  this.snackText = '데이터가 존재하지 않습니다.'
                  break;
              }
            }
            this.razerValue = '';
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))

          
      },

      searchFnWearing(val) {
        console.log('===vavavava', val)
        if(val == null || val.length<1) return;

        //val = val.replace('-', '');

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.locationItems = res.result
            this.locationModel = this.locationItems[0]
            this.clickLocation(this.locationItems[0]);
          })
          .catch(err => {
            console.log(err)
          })
      },

      click(value) {
        if(value == null) {
          this.search = null
          this.searchModel = null
          this.searchItems = []
          return;
        }

        console.log('==== @@@@@', this.datas);
        const len = this.datas.length
        var newRec = {
          // id:this.searchModel.unique_id,
          id:len,
          unique_id:this.searchModel.unique_id,
          item_code:this.searchModel.item_code,
          item_name:this.searchModel.item_name,
          specification:this.searchModel.specification,
          detail_info:this.searchModel.detail_info,
          quan:0,
          checkbox:false
        }

        var check = false;
        this.datas.forEach((data) => {
          var uid = data.unique_id
          var _uid = this.searchModel.unique_id
          if(uid == _uid) check = true
        })

        console.log('===== check', check)

        if(!check) {
          this.datas.push(newRec)
          this.openDialogLotno(this.datas[this.datas.length-1]);
        }

        // search clear
        this.search = null
        this.searchModel = null
        this.searchItems = []
      },

      onDecode (result) {
        alert('onDecode :' + result)
        console.log('==== on decode: ', result)
        this.itemCodeValue = result;
        this.showBarcode = !this.showBarcode;
      },

      onError (result) {
        alert('onError :' + result)
        console.log('==== on Error', result)
      },

      selectLocation() {
        this.updateSelectedObject();
        if(this.selectedObject == null || this.selectedObject.length<1) {
          this.dialogNext = true;
          return;
        } else {
          for(var i=0; i<this.selectedObject.length; i++) {
            var obj = this.selectedObject[i]
            var quan = obj.quan
            if(quan<1) {
              this.dialogZero = true;
              return
            }
          }
        }
        this.$router.push({name:'LOCATION', params: {selectedItems:this.selectedObject}})
      },

      pageBack() {
        this.$router.push('home')
      },

      click_row(obj, row) {
        if(this.selectedRecords==null || this.selectedRecords.length<1) {
          this.selectedRecords.push(obj);
        } else {
          var obj_id = obj.unique_id
          var check = false;
          for(var i in this.selectedRecords) {
              var rec = this.selectedRecords[i]
              var id = rec.unique_id
              if(id == obj_id) {
                check = true
                this.selectedRecords.splice(i, 1)
                break;
              }
          }

          if(!check) {
            this.selectedRecords.push(obj);
          }
        }
      },

      rowClass(obj) {
        // var names = values.map(function(value){ return value.name })
        var id = obj.unique_id
        var is_selected = false

        this.selectedRecords.map(function(rec) {
          var _id = rec.unique_id
          // console.log('==========@@@@', id + ' : ' + _id)
          if(id == _id) {
            is_selected = true
          }
        })

        // console.log('==========@@@@ is_selected', is_selected)
        // if(is_selected) {
        //   const rowClass = 'vvvvvvv'
        //   console.log('==========@@@@ rowClass', rowClass)
        //   return rowClass
        // }
        // const rowClass = 'v-data-table__custom_selected_blue '
        const rowClass = is_selected == true ? 'v-data-table__custom_selected_blue ' : ''
        return rowClass
      },

      changeQaun(item) {
        this.dialogQuan = true
        // item.quan = 10;
      },

      save() {

      },

      cancel() {

      },

      removeRow(id) {
        for(var i in this.datas) {
          var data = this.datas[i]
          var uid = data.id
          if(uid == id) {
            this.datas.splice(i, 1)
          }
        }
      },
      nextId() {
        if(this.datas==null || this.datas.length<1) return 1;
        var arrs = [];
        this.datas.map(function(data, index, array) {
          arrs.push(data.id)
        });
        arrs.sort(function(a, b)  {
          return b - a;
        });
        var result = arrs[0] * 1;

        return result+1;
      },
      copyRow(item) {
        var id = this.nextId();
        var len = 0;
        var obj = {};

        this.datas.forEach((data, i) => {
          var uid = data.unique_id
          if(uid == item.unique_id) {
            len = len + i;
            obj = {
              id:id,
              unique_id:data.unique_id,
              item_code:data.item_code,
              item_name:data.item_name,
              specification:data.specification,
              detail_info:data.detail_info,
              quan:0,
              checkbox:false
            }
          }
        })

        // this.datas.push(obj)
        this.datas.splice(len, 0, obj)
        this.openDialog(this.datas[len]);
      },

      clickCheckBox(item) {
        console.log('==== clickCheckBox', item.checkbox);
        if(item.checkbox==false){
          this.rowClicked(item)
        }
      },

      rowClicked(row) {
        console.log('==== rowClicked', row);
        row.checkbox = !row.checkbox // card 클릭시 checkbox change
        // this.toggleSelection(row.unique_id);
        this.toggleSelection(row.id);        
      },
      toggleSelection(keyID) {
        if (this.selectedRecords.includes(keyID)) {
          this.selectedRecords = this.selectedRecords.filter(
            selectedKeyID => selectedKeyID !== keyID
          );
        } else {
          this.selectedRecords.push(keyID);
        }
      },

      updateSelectedObject() {
        this.selectedObject.splice(0, this.selectedObject.length)
        for(var i in this.selectedRecords) {
          var _id = this.selectedRecords[i];
          for(var j in this.datas) {
            var o = this.datas[j]
            var __id = o.id
            if(_id == __id) {
              this.selectedObject.push(o)
            }
          }
        }
      },

      clickLocation(obj) {
        this.selectedBin = obj.unique_id
      },

      openWearing() {
        this.updateSelectedObject();
        if(this.selectedObject == null || this.selectedObject.length<1) {
          this.dialogNext = true;
          return;
        } else {
          for(var i=0; i<this.selectedObject.length; i++) {
            var obj = this.selectedObject[i]
            var quan = obj.quan
            if(quan<1) {
              this.dialogZero = true;
              return
            }
          }
        }
        this.dialogWearing = true
      },

      execWearing() {
        // item + rack api send
        
        console.log('===== exec wearing items', this.selectedObject)
        console.log('===== exec wearing bin', this.selectedBin)

        if(this.selectedBin==null || this.selectedBin.length<1) {
          this.dialogAlaram = true;
          this.alaramText = '위치를 선택해주세요';
          return;
        }

        var item_uids = [];
        var item_quans = [];
        var item_lotnos = [];
        var lotno;
        for(var i in this.selectedObject) {
          var o = this.selectedObject[i]
          var unique_id = o.unique_id
          var quan = o.quan * 1
          lotno = o.lotno
          item_uids.push(unique_id)
          item_quans.push(quan)
          //item_lotnos.push(lotno)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execWearing';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          item_uids:item_uids,
          item_quans:item_quans,
          //item_lotnos:item_lotnos,
          set_lotno:lotno,
          bin_uid:this.selectedBin
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        console.log(url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.selectedObject.forEach((obj) => {
              var id = obj.id;
              var i=0;
              this.datas.forEach((data) => {
                var _id = data.id;
                if(id == _id) {
                  this.datas.splice(i,1);
                }
                i++;
              });
            });

            // dialog close
            this.dialogWearing = false
          })
      },

    }
  }
</script>

<style >
  .card-text-item-code  {
    font-size: 1.3em;
    font-weight: bold;
    color:#264b8c !important;
  }
  .card-text-item-code-click {
    font-size: 1.3em;
    font-weight: bold;
    color:#ffffff !important;
  }
  .card-text-item-name  {
    font-size: 1.2em;
    font-weight: 400;
    color:#264b8c !important;
  }
  .card-text-item-name-click {
    font-size: 1.2em;
    font-weight: 400;
    color:#ffffff !important;
  }
  .card-text-specification  {
    color:#264b8c !important;
  }
  .card-text-specification-click {
    color:#ffffff !important;
  }
</style>