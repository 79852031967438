<!-- StockSearch.vue -->
 
<template>
  <div id="stockSearch" class="content">
  </div>

</template>
 
<script>
export default {
  components: {
    
  }
};
</script>