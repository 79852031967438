

<script>
import '@/assets/style/main.css';

export default {
    name:'Base',
}

</script>

<style scoped lang="css">
    @import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css";
</style>