<!-- Release.vue -->
 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="selectType"
            :items="selectTypes"
            menu-props="auto"
            outlined
            hide-details
            @change="typeClick"
          ></v-select>
        </v-col>
        <v-col cols="8">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"
            
            clearable
            hide-details
            hide-selected
            hide-no-data
            
            :item-text="autoText"
            :item-value="autoValue"
            :label="autoLabel"
            return-object
            @click:append-outer="clickScanType($store.getters.getCodeType)"
            @change="click"
            @click:clear="clear"
            @click="search_void"
            :autofocus="true"
            @focus="$event.target.select()"
            ref="input_searchItem"
            menu-props="{'closeOnContentClick': true}"
            @input="inputChanged"
          >           
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
      
    <v-data-table
      v-model="selectedRecords"
      :headers="columns"
      :mobile-breakpoint="1024"
      :items="datas"
      item-key="id"
      hide-default-footer
      hide-default-header
      no-data-text=""
      class="elevation-1"
      style="width:100%; height:78%; overflow:scroll;"
      disable-pagination
    >
      <template v-slot:item="row" >
        <v-toolbar
          light
          color='#cddaed'
        >
          <v-checkbox
            v-model="row.item.checkbox"
            :disabled=true
          ></v-checkbox>
        </v-toolbar>
        <v-card
          class="mx-auto"
          :color="selectedRecords.indexOf(row.item.id)>-1?'#264b8c':'white'" 
          @click="rowClicked(row.item)"
          dark
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.id"
        >
           <v-card-title
            :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-code-click':'card-text-item-code'" >
            {{row.item.item_code}} 
            <v-spacer></v-spacer>
            {{row.item.bin_code}}
          </v-card-title>
          <v-card-subtitle
            :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-name-click':'card-text-item-name'" >
            {{row.item.lotno}}<br>
            {{row.item.item_name}}<br>
            {{row.item.specification}}<br>
            {{row.item.detail_info}}
          </v-card-subtitle>
          <v-card-text style="font-size:20px; font-weight:bold;" class="d-flex justify-start row-2" >
          <v-spacer></v-spacer> 
          <v-btn class="mx-1" color="#46B2E1" style="font-size:1.2em;"
            @click="openDialog(row.item)"
            @click.stop
          >
            {{row.item.quan}}
          </v-btn>         
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >    
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="width:40%; font-size:25px;"
            @click="dialog = true"
          >
            출고
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-bottom-sheet
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="text-align:center; font-size:4vw; font-weight:bold;">
          해당 항목을 출고하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            color="blue darken-1"
            text
            @click="execRelease"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            출고
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="cancelRelease"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>     
      </v-card>
    </v-bottom-sheet>

    <v-dialog v-model="dialog_quan" max-width="500px">
      <v-card>
        <v-card-title>
          출고 수량 수정
        </v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="dialogValue" 
            label="Value" 
            :autofocus="true"
            @keydown.enter="confirmDialog"
            :rules="[quan_rule]"
            @focus="$event.target.select()"
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="confirmDialog">Confirm</v-btn>
          <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogRazer"
      width="50%"
      max-width="1000px"
      :scrollable="false"
    >
      <div style="width:100%; height:80%; background:white; padding:2%;">
        <v-text-field
          v-model="razerValue"
          label="검색"
          autofocus
        >
        </v-text-field>
      </div>

      <v-snackbar
          v-model="snackRazer"
          :timeout="snackTimeRazer"
          :color="snackColorRazer"
      >
          {{ snackTextRazer }}
      </v-snackbar>
    </v-dialog>

    <v-dialog
      persistent
      light
      v-model="dialogQuan"
      max-width="400"
    >
      <v-text-field
        v-model="numberValue"
        hide-details
        single-line
        type="number"
        :autofocus="true"
        @focus="$event.target.select()"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogNext"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          품목을 선택해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogNext = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogZero"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          수량을 확인해주세요
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogZero = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet
      v-model="scan_sheet"
    >
      <div style="width:100%; height:100%; background:white;">
        <v-container
          class="text-center"
          width="100%"
          scrollable
        >
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('BARCODE')"
          >
            바코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('QRCODE')"
          >
            QR코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('RAZER')"
          >
            레이저 스캐너
          </v-btn>
        </v-container>
      </div>
    </v-bottom-sheet>

  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"
  export default {
    mounted() {
      var scan_result = this.$route.params.scan_result;
      if(scan_result!=null && scan_result!=undefined) {
        this.itemCodeValue = scan_result;
      }

      var me = this

      window.onpopstate = function(event) {
        if(me.isScan) {
          me.isScan = false
          me.scanner.stop();
        }
      };
    },
    components: {
      
    },

    watch: {
      searchModel(val) {

      },

      search(val) {
        console.log('== @@@@', val)
        if(val == null || val.length<1) return;
        // this.isLoading = true

        var type = "";
        if(this.selectType=='품목') type = "품목"
        if(this.selectType=='위치') type = "위치"
        if(this.selectType=='LOTNO') type = "LOTNO"
   
        console.log('== searchType', this.searchType)

        var url = '';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          // bin_code:val
        }

        // switch(this.searchType) {
        //   case '품목':
        //     pObj.item_name = val;
        //     url = this.$vBACKEND_URL + '/item.do?method=readItem';
        //     break;
        //   case '위치':
        //     pObj.bin_code = val;
        //     url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        //   break;
        // }

        switch(this.searchType) {
          case '품목':
            pObj.search_item = val;
            url = this.$vBACKEND_URL + '/item.do?method=readItem';
            break;
          case 'LOTNO':
            pObj.lotno=val;
            pObj.start=0;
            pObj.limit=100;
            pObj.gubun='IN';
            pObj.apiType='ALL';
            url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
            break;
        }


        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems= [];
            switch(this.searchType) {
              case '품목':
                this.searchItems = res.result;
                break;
                case 'LOTNO':
                this.searchItems = res.datas;
                break;
          }
            console.log(res);
            console.log(this.searchItems);
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      dialogScan() {
        if(!this.dialogScan) {
          const isScanning = this.scanner.isScanning;
          if(isScanning) this.scanner.stop();
        }
      },

      razerValue() {
        if(this.razerValue!=null && this.razerValue.length>0) {
          setTimeout(function() {
            this.searchFn(this.razerValue);
          }.bind(this), 1500);
        }
      }
    },

    data () {
      return {
        selectType:'품목',
        selectTypes:['품목','LOTNO'],        
        selectedRecords:[],
        datas: [],
        columns: [
          { text: 'LOTNO', sortable: false, value: 'lotno', align:'center'},
          {text:'품명', value:'item_name'},
          {text:'규격', value:'specification'},
          {text:'수량', value:'quan'},
        ],
        max25chars: v => v.length <= 25 || 'Input too long!',
        quan_rule: v => {
          v = v * 1
          if(v < 1) {
            return '1개 이상 입력해주세요.'
          }
          else{
            return true;
          }
        },
        dialogQuan:false,
        dialogNext:false,
        dialogZero:false,
        dialog:false,
        numberValue:0,
        searchModel:null,
        search: null,
        searchItems:[],
        isLoading:false,
        dialogScan:false,
        selectedObject:[],
        showBarcode:false,
        itemCodeValue:null,

        isScan:false,
        scanner:null,
        qrbox:null,
        fps:null,

        searchTypes:[
          '품목', '위치', 'LOTNO'
        ],
        searchType:'품목',

        autoLabel:'품번/품명 검색',     // or 위치검색
        autoText:'search_name',  // or bin_code
        autoValue:'unique_id',   // or bin_code

        scan_sheet:false,
        scanType:'',
        dialogRazer:false,
        razerValue:'',

        snack:false,
        snackTime:3000,
        snackColor:'',
        snackText:'',

        snackRazer:false,
        snackTimeRazer:3000,
        snackColorRazer:'',
        snackTextRazer:'',

        dialog_quan:false,
        selectedItem:null,
        dialogValue:0, 
        input_searchItem:null,     
      }
    },

    methods: {
      inputChanged() {
          //↓ For clear v-menu slot
          this.$refs.input_searchItem.blur()
          setTimeout(() => {
              this.$refs.input_searchItem.focus()
          }, 500)
      },

      search_void() {
        var type = "";
        if(this.selectType=='품목') type = "품목"
        if(this.selectType=='위치') type = "위치"
        if(this.selectType=='LOTNO') type = "LOTNO"
   
        console.log('== searchType', this.searchType)

        var url = '';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          // bin_code:val
        }

        switch(this.searchType) {
          case '품목':
            pObj.search_item = '';
            url = this.$vBACKEND_URL + '/item.do?method=readItem';
            break;
          case 'LOTNO':
            pObj.lotno='';
            pObj.start=0;
            pObj.limit=100;
            pObj.gubun='IN';
            pObj.apiType='ALL';
            url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
            break;
        }


        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems= [];
            switch(this.searchType) {
              case '품목':
                this.searchItems = res.result;
                break;
                case 'LOTNO':
                this.searchItems = res.datas;
                break;
          }
            console.log(res);
            console.log(this.searchItems);
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      openDialog(item) {
        this.selectedItem = item;
        this.dialogValue = item.quan;
        this.dialog_quan = true;
      },
      confirmDialog() {
        // Update the button text with the dialog value
        this.rowClicked(this.selectedItem);
        console.log(this.selectedItem); // Do something with the updated item
        //this.selectedRecords.quan = this.dialogValue;
        this.selectedItem.quan = this.dialogValue;
        this.clickCheckBox(this.selectedItem);
        this.closeDialog();
        this.$refs.input_searchItem.focus();
      },
      closeDialog() {
        this.dialog_quan = false;
        this.$refs.input_searchItem.focus();
      },
      clickCheckBox(item) {
        console.log('==== clickCheckBox', item.checkbox);
        if(item.checkbox==false){
          this.rowClicked(item)
        }
      },
      clickScanType(type) {
        console.log('=== clickScanType type', type)
        this.scan_sheet = false;
        this.scanType = type;

        switch(type) {
          case 'BARCODE':
          case 'QRCODE':
            this.openScanner(type);
            break;
          case 'RAZER':
            this.dialogRazer = true
            break;
        }
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);
            // this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 120} }, this.onScanSuccess);
        });
      },
      onScanSuccess(qrCodeMessage) {
          //alert(qrCodeMessage)
          //if(qrCodeMessage!=null) qrCodeMessage = qrCodeMessage.replace('-', '');
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      readRazerSuccess(value) {
        this.searchFn(value);
        // this.dialogRazer = false;
        // this.razerValue = '';
      },

      searchFn(val) {
        if(val == null || val.length<1) return;
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          barcode:val
          // bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        console.log('searchFn:' + url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.click(this.searchItems[0])
            } else {
              switch(this.scanType) {
                case 'RAZER':
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snackRazer = true
                  this.snackTimeRazer = 5000
                  this.snackColorRazer = 'error'
                  this.snackTextRazer = '데이터가 존재하지 않습니다.'
                  break;
                default:
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snack = true
                  this.snackTime = 5000
                  this.snackColor = 'error'
                  this.snackText = '데이터가 존재하지 않습니다.'
                  break;
              }
            }
            this.razerValue = '';
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      click(obj) {
        console.log('=== obj', obj);
        // if(obj == null) return;
        if(obj == null) {
          this.search = null
          this.searchModel = null
          this.searchItems = []
          return;
        }

        var url = '';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          // bin_uid:obj.unique_id
        }
        switch(this.searchType) {
          case '품목':
            pObj.uid_item = obj.unique_id;
            url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
            break;
          case 'LOTNO':
            pObj.uid_item = obj.unique_id;
            pObj.lotno=obj.lotno;
            pObj.start=0;
            pObj.limit=100;
            pObj.gubun='IN';
            pObj.apiType='ALL';
            url = this.$vBACKEND_URL + '/stock.do?method=readLocationHistory';
            break;
          case '위치':
            pObj.bin_uid = obj.unique_id;
            url = this.$vBACKEND_URL + '/stock.do?method=readLocation';
          break;
        } 

        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            console.log('==== resres', res);
            switch(this.searchType) {
              case '품목':
                this.datas = res.result
                break;
              case 'LOTNO':
                this.datas = res.datas
                break;
            }              
            
            this.openDialog(this.datas[this.datas.length-1]);
          })
          .catch(err => {
            console.log('=== =err' + err)
          })
          .finally(() => {
            // search clear
            this.search = null
            this.searchModel = null
            this.searchItems = []
          })
        
      },

      onDecode (result) {
        console.log('==== on decode: ', result)
        this.itemCodeValue = result;
        this.showBarcode = !this.showBarcode;
      },

      onError (result) {
        console.log('==== on Error', result)
      },

      execRelease() {
        if(this.selectedRecords == null || this.selectedRecords.length < 1) {
          this.dialogNext = true
          return;
        }
        var location_uids = [];
        var location_quans = [];
        for(var i in this.selectedRecords) {
          var unique_id = this.selectedRecords[i];
          for(var j in this.datas) {
            var data = this.datas[j];
            var uid = data.unique_id;
            if(unique_id == uid) {
              var quan = data.quan
              if(quan == null || quan < 1) {
                this.dialogZero = true;
                return;
              }
              location_uids.push(unique_id)
              location_quans.push(quan)
              continue;
            }
          }
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execRelease';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          location_uids:location_uids,
          location_quans:location_quans
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        console.log(url);

        fetch(url)
          .then(() => {
            this.afterExecRelease(location_uids)
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialog = false;
          })
      },

      afterExecRelease(uids) {
        if(uids!=null && uids.length>0) {
          for(var i in uids) {
            var uid = uids[i]
            for(var j in this.datas) {
              var data = this.datas[j];
              var _uid = data.unique_id
              if(uid == _uid) {
                this.datas.splice(j, 1)
                continue;
              }
            }
          }
        }
      },

      cancelRelease() {
        this.dialog = false
      },

      pageBack() {
        this.$router.push('home')
      },
      
      save() {

      },

      cancel() {

      },

      rowClicked(row) {
        console.log('==== rowClicked', row);
        row.checkbox = !row.checkbox // card 클릭시 checkbox change
        // this.toggleSelection(row.unique_id);
        this.toggleSelection(row.unique_id);
      },
      toggleSelection(keyID) {
        if (this.selectedRecords.includes(keyID)) {
          this.selectedRecords = this.selectedRecords.filter(
            selectedKeyID => selectedKeyID !== keyID
          );
        } else {
          this.selectedRecords.push(keyID);
        }
      },

      resetDatas() {
        this.datas = [];
      },

      clear() {
        this.resetDatas();
      },
      // searchFn(val) {
      //   var type = "";
      //   if(this.selectType=='품목') type = "ITEM_NAME"
      //   if(this.selectType=='위치') type = "BIN"
      //   if(this.selectType=='LOTNO') type = "lotno"

      //   var url = this.$vBACKEND_URL + '/stock.do?method=searchStockTypeCombo';
      //   var pObj = {
      //     uid_company:this.$store.getters.getUidCompany,
      //     selectType:type,
      //     value:val
      //   }
      //   var params = '';
      //   for(var key in pObj) {
      //     params += '&' + key + '=' + pObj[key]
      //   }

      //   url += params;

      //   fetch(url)
      //     .then(res => res.clone().json())
      //     .then(res => {
      //       this.searchItems = res.datas
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      // },
      typeClick(val) {
        switch(val) {
          case '품목':
            this.autoLabel = '품번/품명 검색'
            this.autoText = 'search_name'
            this.autoValue = 'unique_id'
            this.searchType = '품목'
            break;
          case '위치':
            this.autoLabel = '위치검색'
            this.autoText = 'bin_code'
            this.autoValue = 'bin_code'
            this.searchType = 'BIN'
            break;
          case 'LOTNO':
            this.autoLabel = 'LOT검색'
            this.autoText = 'lotno'
            this.autoValue = 'lotno'
            this.searchType = 'LOTNO'
            break;
        }

        this.searchModel = null
        this.searchItems = []


      //   // this.searchModel = null
      //   // this.searchItems = []
      //   // this.search = null

      //   // this.datas = []
      //   // this.sumDatas = []
      //   // this.selectedRecords = []

      //   // switch(val) {
      //   //   case 'BIN':
      //   //     this.searchText = 'bin_code'
      //   //     this.searchValue = 'bin_code'
      //   //     break;
      //   //   case '창고':
      //   //     this.searchText = 'wh_code'
      //   //     this.searchValue = 'wh_code'
      //   //     break;
      //   //   case '동':
      //   //     this.searchText = 'rack_code'
      //   //     this.searchValue = 'rack_code'
      //   //     break;
      //   // }

      //   // this.searchFn('');
      },

      searchTypeClick(val) {
        switch(val) {
          case '품목':
            this.autoLabel = '품목검색'
            this.autoText = 'search_name'
            this.autoValue = 'unique_id'
            break;
          case '위치':
            this.autoLabel = '위치검색'
            this.autoText = 'bin_code'
            this.autoValue = 'bin_code'
            break;
          case 'LOTNO':
            this.autoLabel = 'LOT검색'
            this.autoText = 'lotno'
            this.autoValue = 'lotno'
            break;
        }

        this.searchModel = null
        this.searchItems = []
      }

    }
  }
</script>

<style scoped>
  .card-text-item-code  {
    font-size: 1.3em;
    font-weight: bold;
    color:#264b8c !important;
  }
  .card-text-item-code-click {
    font-size: 1.3em;
    font-weight: bold;
    color:#ffffff !important;
  }
  .card-text-item-name  {
    font-size: 1.2em;
    font-weight: 400;
    color:#264b8c !important;
  }
  .card-text-item-name-click {
    font-size: 1.2em;
    font-weight: 400;
    color:#ffffff !important;
  }
  .card-text-specification  {
    color:#264b8c !important;
  }
  .card-text-specification-click {
    color:#ffffff !important;
  }
</style>