<template>
  <div class="content" style="height:100%; min-height:100%; position:fixed;">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="padding:5%;"
    >
      <p style="text-align:center;">아래 정보를 입력해주세요</p>

      <v-text-field
        v-model="location_code"
        label="*위치정보코드"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="rack_col"
        label="*행"
        outlined
        clearable
        required
      ></v-text-field>

      <v-text-field
        v-model="rack_row"
        label="*열"
        outlined
        clearable
        required
      ></v-text-field>
      
      <v-text-field
        v-model="wh_name"
        label="창고명"
        outlined
        clearable
      ></v-text-field>

      <v-text-field
        v-model="rack_name"
        label="랙명"
        outlined
        clearable
      ></v-text-field>

      <div style="text-align:right;">
        <v-btn
          text
          style="background-color:green;"
          :color="'white'"
          @click="checkDuplicate"
        >
          중복확인
        </v-btn>
      </div>
      
      <div style="text-align:center; margin-top:10%;">
        <v-btn
          text
          @click="registEvent"
          class="mr-1"
          style="width:100%; background-color: rgb(103, 173, 219); color:white;"
        >
          등록하기
        </v-btn>
      </div>
    </v-form>

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan" class="fullscreen" 
        
      >
        <StreamBarcodeReader
          @decode="onDecode"
          @error="onError"
        ></StreamBarcodeReader>
      </div>
    </v-dialog>

  </div>
</template>
 
<script>
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import { ImageBarcodeReader } from "vue-barcode-reader";

  export default {
    mounted() {
      
    },
    components: {
      StreamBarcodeReader:StreamBarcodeReader,
    },

    watch: {
      
    },

    data () {
      return {
        valid: true,

        item_code:null,
        item_name:null,
        specification:null,
        safe_quan:null,

        rack_name:null,
        wh_name:null,
        location_code:null,
        rack_row:null,
        rack_col:null,

        is_duplicate:false,

        dialogScan:false,
        dialogAlaram:false,
        alaramText:'',

        rack_barcode:null,
        
      }
    },

    methods: {
      openScanner() {
        this.dialogScan = true
      },

      pageBack() {
        this.$router.push('home')
      },

      IsDuplicateUpdate() {
        this.is_duplicate = false
      },
      
      checkDuplicate() {
        this.is_duplicate = true
      },

      registEvent() {
        if(!this.is_duplicate) {
          this.dialogAlaram = true
          this.alaramText = '중복확인을 해주세요'
          return
        }
      },

      download_event() {
        console.log('download_event')
      },

      upload_event() {
        console.log('upload_event')
      },

      barcode_down_event() {
        console.log('barcode_down_event')
      },

      onDecode (result) {
        this.rack_barcode = result;
        this.dialogScan = !this.dialogScan;
      },

      onError (result) {
        console.log('==== on Error', result)
        this.dialogAlaram = true
        this.alaramText = result
      },

    }
  }
</script>