<template>
  <v-dialog
    v-model="dialog"
    persistent
    light
    max-width="400"
  >
    <v-card>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        style="padding:5%;"
      >
        <v-text-field
          v-model="user_id"
          label="아이디"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="user_name"
          label="이름"
          required
          readonly
        ></v-text-field>

        <v-text-field
          v-model="user_grade"
          label="권한"
          readonly
        ></v-text-field>

        <v-text-field
          v-model="user_email"
          label="이메일"
          clearable
          required
        ></v-text-field>
        
        <div style="text-align:center; margin-top:20%;">
          <v-btn
            text
            class="mr-1"
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
          >
            확인
          </v-btn>
          <v-btn
            text
            class="mr-1"
            style="width:40%; background-color: rgb(103, 173, 219); color:white;"
            @click="closeDialog"
          >
            취소
          </v-btn>
        </div>

      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name:'UserInfo',
  props: {
    userInfoDialog:Boolean
  },
  mounted() {
    this.settingUserData()
  },
  watch: {
    userInfoDialog() {
      this.dialog = this.userInfoDialog
    }
  },
  data () {
    return {
      dialog:this.userInfoDialog,
      valid:true,

      user_id:null,
      user_name:null,
      user_email:null,
      user_grade:[]
    }
  },
  components: {
    
  },
  methods: {
    closeDialog() {
      // this.dialog = false
      this.$emit('close:dialog', false)
    },

    settingUserData() {
      const loginDatas = this.$store.getters.getLoginDatas

      this.user_id = loginDatas.user_id
      this.user_name = loginDatas.user_name
      // this.user_email = loginDatas.user_id
      // this.user_grade = loginDatas.user_id
    }
  },
};
</script>