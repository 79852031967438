<!-- StockMove.vue -->
 
<template>
  <div class="content">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"
            
            clearable
            hide-details
            hide-no-data
            item-text="search_name"
            item-value="unique_id"
            return-object
            label="품번/품명 검색"
            @click:append-outer="clickScanType($store.getters.getCodeType)"
            @change="click"
            @click:clear="clear"
            @click="search_void"
            :autofocus="true"
            menu-props="{'closeOnContentClick': true}"
            @input="inputChanged"
            ref="input_searchItem"
          >
            <!-- <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left>
                  mdi-format-list-bulleted-square
                </v-icon>
                <span v-text="item.search_name"></span>
              </v-chip>
            </template> -->
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
    
    <v-data-table
      v-model="selectedRecords"
      :headers="columns"
      :mobile-breakpoint="1024"
      :items="datas"
      item-key="id"
      hide-default-footer
      hide-default-header
      no-data-text=""
      class="elevation-1"
      style="width:100%; height:70%; overflow:scroll;"
      disable-pagination
    >
      <template v-slot:item="row" >
        <v-toolbar
          light
          color='#cddaed'
        >
          <v-checkbox
            v-model="row.item.checkbox"
            :disabled="true"
          ></v-checkbox>
        </v-toolbar>
        <v-card
          class="mx-auto"
          :color="selectedRecords.indexOf(row.item.id)>-1?'#264b8c':'white'" 
          @click="rowClicked(row.item)"
          dark
          width="100%"
          style="margin-bottom:10px;"
          :key="row.item.id"
          @click.stop
          >
           <v-card-title
            :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-code-click':'card-text-item-code'" >
            {{row.item.item_code}} 
            <v-spacer></v-spacer>
            {{row.item.bin_code}}
          </v-card-title>
          <v-card-subtitle
            :class="selectedRecords.indexOf(row.item.id)>-1?'card-text-item-name-click':'card-text-item-name'" >
            Lot:{{row.item.lotno}}<br>
            {{row.item.item_name}}<br>
            {{row.item.specification}}<br>
            {{row.item.detail_info}}

          </v-card-subtitle>
          <v-card-text style="font-size:20px; font-weight:bold;" class="d-flex justify-start row-2" >
          <v-spacer></v-spacer> 
          <v-edit-dialog
            :return-value.sync="row.item.quan"
            large
            save-text="확인"
            cancel-text="취소"
            @save="save"
            @cancel="cancel"
          >
          <!-- <v-btn>{{row.item.quan * 1}}</v-btn> -->
            <v-btn class="mx-1" color="#46B2E1" style="font-size:1.2em;"
            @click="modify_quan(row.item)"
            >
              {{row.item.quan * 1}}
            </v-btn>
            <template v-slot:input>
              <v-text-field
                v-model="row.item.quan"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
                @focus="$event.target.select()"
              >
                <!-- <template v-slot:append-outer>
                  <v-btn
                    tile
                    color="primary"
                    @click="updateDialogQuan"
                  >
                    확인
                  </v-btn>
                </template> -->
              </v-text-field>
            </template>
          </v-edit-dialog>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="margin-right: 5%; width:30%; font-size:25px;"
            @click="adjustQuan"
          >
            조정
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="width:30%; font-size:25px;"
            @click="dialogMove = true"
          >
            이동
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-bottom-sheet
      v-model="dialogMove"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">재고이동</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-autocomplete
                  label="위치검색"
                  :append-outer-icon="'mdi-barcode-scan'"
                  v-model="stockhModel"
                  :items="stockItems"
                  :loading="isLoadingStock"
                  :search-input.sync="stockSearch"

                  clearable
                  hide-details
                  hide-selected
                  return-object

                  item-text="bin_code"
                  item-value="bin_code"

                  @click:append-outer="openScannerMove($store.getters.getCodeType)"
                  @keydown.enter="stockMoveHandler"
                  @focus="$event.target.select()"
                  @change="clickMove"
                  @click="stockSearch_void"
                  menu-props="{'closeOnContentClick': true}"
                  @input="inputChanged2"
                  ref="input_searchItem2"
      
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="stockMoveHandler"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            이동
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogMove = false"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-model="dialogAdjustQuan"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text style="text-align:center; font-size:4vw; font-weight:bold;">
          수량을 조정하시겠습니까?
        </v-card-text>
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            color="blue darken-1"
            text
            @click="changeQuan"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            확인
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogAdjustQuan = false"
            style="font-size:1.5em; width:50%; background-color:#F1F1F1;"
          >
            취소
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    
    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogRazer"
      width="50%"
      max-width="1000px"
      :scrollable="false"
    >
      <div style="width:100%; height:80%; background:white; padding:2%;">
        <v-text-field
          v-model="razerValue"
          label="검색"
          autofocus
        >
        </v-text-field>
      </div>

      <v-snackbar
          v-model="snackRazer"
          :timeout="snackTimeRazer"
          :color="snackColorRazer"
      >
          {{ snackTextRazer }}
      </v-snackbar>
    </v-dialog>
    
    <v-dialog
      v-model="dialogScanMove"
      max-width="400"
    >
      <div id="barcodeScanMove">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet
      v-model="scan_sheet"
    >
      <div style="width:100%; height:100%; background:white;">
        <v-container
          class="text-center"
          width="100%"
          scrollable
        >
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('BARCODE')"
          >
            바코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('QRCODE')"
          >
            QR코드
          </v-btn>
          <v-btn
            class="mt-6"
            text
            style="border:1px solid; width:60%; height:50px; background:#84bbfb; "
            @click="clickScanType('RAZER')"
          >
            레이저 스캐너
          </v-btn>
        </v-container>
      </div>
    </v-bottom-sheet>

    <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
    >
        {{ snackText }}
    </v-snackbar>

  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    name:'move',
    mounted() {
      
    },
    components: {
      
    },
    data () {
      return {
        max25chars: v => v.length <= 25 || 'Input too long!',
        alaramText:'',
        dialogAlaram:false,
        dialogMove:false,
        dialogQuan:false,
        dialogNext:false,
        dialogZero:false,
        numberValue:0,
        searchModel:null,
        search: null,
        searchItems:[],
        isLoading:false,
        isLoadingStock:false,
        dialogScan:false,
        dialogScanMove:false,
        dialogAdjustQuan:false,
        selectedRecords:[],
        selectedObject:[],
        showBarcode:false,
        itemCodeValue:null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        columns: [
          
        ],
        datas: [],

        scanner:null,
        qrbox:null,
        fps:null,

        stockhModel:null,
        stockSearch: null,
        stockItems:[],
        selectedStock:null,

        scan_sheet:false,
        scanType:'',
        dialogRazer:false,
        razerValue:'',

        snack:false,
        snackTime:3000,
        snackColor:'',
        snackText:'',

        snackRazer:false,
        snackTimeRazer:3000,
        snackColorRazer:'',
        snackTextRazer:'',

        lotno:null,
        set_lotno:null,        
      }
    },

    watch: {
      searchModel (val) {},
      search (val) {
        if(val == null || val.length<1) return;
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        // var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:val
          // item_name:val
          // bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            // console.log('=== this.searchItems', this.searchItems)
            // if(this.searchItems!=null && this.searchItems.length>0) {
            //   this.searchModel = this.searchItems[0];
            //   this.click(this.searchItems[0])
            // }
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      stockhModel (val) {},
      stockSearch (val) {
        if(val == null || val.length<1) return;
        this.isLoadingStock = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.stockItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingStock = false))
      },

      dialogScan() {
        if(!this.dialogScan) {
          const isScanning = this.scanner.isScanning;
          if(isScanning) this.scanner.stop();
        }
      },

      razerValue() {
        if(this.razerValue!=null && this.razerValue.length>0) {
          setTimeout(function() {
            this.searchFn(this.razerValue);
          }.bind(this), 1500);
        }
      }
    },

    methods: {
      inputChanged() {
          //↓ For clear v-menu slot
          this.$refs.input_searchItem.blur()
          setTimeout(() => {
              this.$refs.input_searchItem.focus()
          }, 300)
      },

      inputChanged2() {
          //↓ For clear v-menu slot
          this.$refs.input_searchItem2.blur()
          setTimeout(() => {
              this.$refs.input_searchItem2.focus()
          }, 300)
      },

      stockSearch_void() {
        this.isLoadingStock = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:''
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.stockItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoadingStock = false))
      },

      search_void() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          search_item:''

        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result

          })
          .catch(err => {
            console.log(err)
          })
      },

      clickScanType(type) {
        console.log('=== clickScanType type', type)
        this.scan_sheet = false;
        this.scanType = type;

        switch(type) {
          case 'BARCODE':
          case 'QRCODE':
            this.openScanner(type);
            break;
          case 'RAZER':
            this.dialogRazer = true
            break;
        }
      },
      openScanner(type) {
        this.setIsScan()
         .then(() => {
           this.startScan(type)
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccess);

            
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      readRazerSuccess(value) {
        this.searchFn(value);
        // this.dialogRazer = false;
        // this.razerValue = '';
      },

      openScannerMove(type) {
        this.setIsScanMove()
         .then(() => {
           this.startScanMove(type)
         })
      },
      setIsScanMove() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScanMove = true
            resolve(true)
        })
      },
      startScanMove(type) {
        var height = 50;
        switch(type) {
          case 'BARCODE':
            height = 50;
            break;
          case 'QRCODE':
            height = 250;
            break;
        }        
        this.scanner = new Html5Qrcode(
        "barcodeScanMove", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: height} }, this.onScanSuccessMove);
        });
      },
      onScanSuccessMove(qrCodeMessage) {
          //if(qrCodeMessage!=null) qrCodeMessage = qrCodeMessage.replace('-', '');
          this.searchFnMove(qrCodeMessage);
          this.scanner.stop();
          this.dialogScanMove = false;
      },
      onScanErrorMove(errorMessage) {
          console.log(errorMessage);
      },


      searchFn(val) {
        if(val == null || val.length<1) return;
        // this.isLoading = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          barcode:val
          // item_name:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);
        
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0];
              this.click(this.searchItems[0])
            } else {
              switch(this.scanType) {
                case 'RAZER':
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snackRazer = true
                  this.snackTimeRazer = 5000
                  this.snackColorRazer = 'error'
                  this.snackTextRazer = '데이터가 존재하지 않습니다.'
                  break;
                default:
                  if(this.snack) this.snack = false;
                  if(this.snackRazer) this.snackRazer = false;
                  this.snack = true
                  this.snackTime = 5000
                  this.snackColor = 'error'
                  this.snackText = '데이터가 존재하지 않습니다.'
                  break;
              }
            }
            this.razerValue = '';
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoading = false))
      },

      searchFnMove(val) {
        if(val == null || val.length<1) return;
        // this.isLoadingStock = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.stockItems = res.result

            if(this.stockItems!=null && this.stockItems.length>0) {
              this.stockhModel = this.stockItems[0];
              this.clickMove(this.stockItems[0])
            }
          })
          .catch(err => {
            console.log(err)
          })
          // .finally(() => (this.isLoadingStock = false))
      },

      click(obj) {
        if(obj == null) return;
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          uid_item:obj.unique_id
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.datas = res.result
          })
          .catch(err => {
            
          })
      },

      click_2(obj) {
        if(obj == null) return;
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          uid_item:obj.uid_item
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;
        console.log(url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.datas = res.result
          })
          .catch(err => {
            
          })
      },

      onDecode (result) {
        console.log('==== on decode: ', result)
        this.itemCodeValue = result;
        this.showBarcode = !this.showBarcode;
      },

      onError (result) {
        console.log('==== on Error', result)
      },

      onDecodeMove (result) {
        console.log('==== on decode Move: ', result)
        this.itemCodeValue = result;
        this.showBarcode = !this.showBarcode;
      },

      onErrorMove (result) {
        console.log('==== on Error Move', result)
      },

      selectLocation() {
        this.updateSelectedObject();
        if(this.selectedObject == null || this.selectedObject.length<1) {
          this.dialogNext = true;
          return;
        } else {
          for(var i=0; i<this.selectedObject.length; i++) {
            var obj = this.selectedObject[i]
            var quan = obj.quan
            if(quan<1) {
              this.dialogZero = true;
              return
            }
          }
        }
        this.$router.push({name:'LOCATION', params: {selectedItems:this.selectedObject}})
      },

      pageBack() {
        this.$router.push('home')
      },

      click_row(obj, row) {
        if(this.selectedRecords==null || this.selectedRecords.length<1) {
          this.selectedRecords.push(obj);
        } else {
          var obj_id = obj.unique_id
          var check = false;
          for(var i in this.selectedRecords) {
              var rec = this.selectedRecords[i]
              var id = rec.unique_id
              if(id == obj_id) {
                check = true
                this.selectedRecords.splice(i, 1)
                break;
              }
          }

          if(!check) {
            this.selectedRecords.push(obj);
          }
        }
      },

      rowClass(obj) {
        var id = obj.unique_id
        var is_selected = false

        this.selectedRecords.map(function(rec) {
          var _id = rec.unique_id
          if(id == _id) {
            is_selected = true
          }
        })
        const rowClass = is_selected == true ? 'v-data-table__custom_selected_blue ' : ''
        return rowClass
      },

      save() {
        
      },

      cancel() {

      },

      removeRow(id) {
        for(var i in this.datas) {
          var data = this.datas[i]
          var uid = data.id
          if(uid == id) {
            this.datas.splice(i, 1)
          }
        }
      },
      modify_quan(row)      {
        // if(row.checkbox==undefined){
        //   row.checkbox = !row.checkbox
        //   this.rowClicked(row);
        // }
        this.rowClicked(row);
        this.clickCheckBox(row);

      },
      clickCheckBox(item) {
        console.log(item);
        console.log('==== clickCheckBox', item.checkbox);
        if(item.checkbox==false){
          this.rowClicked(item)
        }
      },

      rowClicked(row) {
        console.log(row.bin_code);
        row.checkbox = !row.checkbox // card 클릭시 checkbox change
        this.toggleSelection(row.id);
      },
      toggleSelection(keyID) {
        if (this.selectedRecords.includes(keyID)) {
          this.selectedRecords = this.selectedRecords.filter(
            selectedKeyID => selectedKeyID !== keyID
          );
        } else {
          this.selectedRecords.push(keyID);
        }
      },

      updateSelectedObject() {
        this.selectedObject.splice(0, this.selectedObject.length)
        for(var i in this.selectedRecords) {
          var _id = this.selectedRecords[i];
          for(var j in this.datas) {
            var o = this.datas[j]
            var __id = o.id
            if(_id == __id) {
              this.selectedObject.push(o)
            }
          }
        }
      },

      stockMoveHandler() {
        this.updateSelectedObject();

        if(this.selectedObject==null || this.selectedObject.length<1) {
          this.dialogAlaram = true;
          this.alaramText = '입고하실 항목을 선택해주세요';
          return;
        }
        if(this.selectedStock==null || this.selectedStock.length<1) {
          this.dialogAlaram = true;
          this.alaramText = '입고하실 위치를 선택해주세요';
          return;
        }

        var location_uids = [];
        var location_quans = [];
        let lotnos = [];
        let set_lotnos = [];

        for(var i in this.selectedRecords) {
          var unique_id = this.selectedRecords[i];
          for(var j in this.datas) {
            var data = this.datas[j];
            var uid = data.unique_id;
            if(unique_id == uid) {
              var quan = data.quan
              if(quan == null || quan < 1) {
                this.dialogAlaram = true;
                this.alaramText = '입고수량을 확인해주세요';
                return;
              }
              location_uids.push(unique_id)
              location_quans.push(quan)
              lotnos.push(data.lotno)
              set_lotnos.push(data.lotno)
              continue;
            }
          }
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execMove';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          location_uids:location_uids,
          location_quans:location_quans,
          bin_uid:this.selectedStock,
          lotnos:lotnos,
          set_lotnos:set_lotnos,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(() => {
            
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            this.dialogMove = false;
          })

          var datas2 = this.datas[0];

          setTimeout(function() {
            this.clear();
            console.log("selectedItem:");
            console.log(datas2);
            this.click_2(datas2);
          }.bind(this), 1000);

      },

      clickMove(obj) {
        this.selectedStock = obj.unique_id;
      }, 

      resetDatas() {
        this.datas = [];
        this.selectedRecords = [];
      },

      clear() {
        this.resetDatas();
      },

      adjustQuan() {
        this.dialogAdjustQuan = true
      },

      changeQuan() {
        this.updateSelectedObject();

        if(this.selectedObject==null || this.selectedObject.length<1) {
          this.dialogAlaram = true
          this.alaramText = '품목을 선택해주세요'
          return;
        }

        let uid_locations = [];
        let location_quans = [];
        this.selectedObject.forEach((data) => {
          var uid = data.unique_id
          var quan = data.quan;
          uid_locations.push(uid)
          location_quans.push(quan)
        })

        var url = this.$vBACKEND_URL + '/stock.do?method=adjustLocationQuan';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          user_uid:this.$store.getters.getUserUid,
          user_id:this.$store.getters.getUserId,
          user_name:this.$store.getters.getUserName,
          uid_locations:uid_locations,
          location_quans:location_quans
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const result = res.result
            if(result) {
              // 성공
              var o = {
                unique_id:this.datas[0].uid_item
              }
              this.click(o);
              this.selectedRecords = [];

              this.snack = true
              this.snackText = '성공'
            } else {
              // 실패
              this.snackText = '실패'
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.dialogAdjustQuan = false
          })
      },

    }
  }
</script>

<style scoped>
  .card-text-item-code  {
    font-size: 1.3em;
    font-weight: bold;
    color:#264b8c !important;
  }
  .card-text-item-code-click {
    font-size: 1.3em;
    font-weight: bold;
    color:#ffffff !important;
  }
  .card-text-item-name  {
    font-size: 1.2em;
    font-weight: 400;
    color:#264b8c !important;
  }
  .card-text-item-name-click {
    font-size: 1.2em;
    font-weight: 400;
    color:#ffffff !important;
  }
  .card-text-specification  {
    color:#264b8c !important;
  }
  .card-text-specification-click {
    color:#ffffff !important;
  }
</style>