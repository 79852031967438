<template>
  <div class="content">
    <v-container>
      <v-row style="margin-top:0px; width:100%;">
        <v-col cols="12">
          <v-autocomplete
            :append-outer-icon="'mdi-barcode-scan'"
            filled
            clear-icon="mdi-close-circle"
            type="text"
            v-model="searchModel"
            :items="searchItems"
            :search-input.sync="search"
            
            clearable
            hide-details
            item-text="bin_code"
            item-value="bin_code"
            return-object
            label="위치검색"
            solo
            @click:append-outer="openScanner"
            @change="click"
            @click:clear="clear"
          >
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
              >
                <v-icon left>
                  mdi-format-list-bulleted-square
                </v-icon>
                <span v-text="item.bin_code"></span>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row style="margin-top:0px; width:100%;">
        <v-col cols="12">
          <v-btn
            width="100%"
            color='orange'
            @click="getDatas"
          >
            <v-card-text>
              조회
            </v-card-text>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

      <v-data-table
        v-model="selectedItem"
        :headers="columns"
        :mobile-breakpoint="1024"
        :items="datas"
        hide-default-footer
        class="elevation-1"
        style="width:100%; height:70%; overflow:scroll;"
      >
        <template v-slot:item="row" >
          <v-card
            class="mx-auto"
            color="#264b8c"
            width="100%"
            style="margin-bottom:10px;"
            :key="row.item.unique_id"
          >
            <v-card-text style="font-size:18px; font-weight:bold; padding: 1%; color:white;" class="d-flex justify-start row-1" >
              &nbsp;{{row.item.rack_code}}
              <v-spacer></v-spacer> 
              &nbsp;{{row.item.rack_rate}}
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>

    <!-- <div class="text-center pt-2">
      <v-row
        rows="5"
        sm="3"
      >
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="margin-right: 5%;"
            @click="() => this.$router.push('home')"
          >
            이전
          </v-btn>
        </v-col>
      </v-row>
    </div> -->

    <v-dialog
      v-model="dialogScan"
      max-width="400"
    >
      <div id="barcodeScan">
      </div>
    </v-dialog>

    <v-dialog
      v-model="dialogAlaram"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          알림
        </v-card-title>
        <v-card-text>
          {{alaramText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="() => dialogAlaram = false"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
 
<script>
  import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode"

  export default {
    mounted() {
      
    },
    components: {
     
    },

    watch: {
      searchModel(val) {
        if(val!=null) {
          this.selectedBinCode = val.unique_id
        } else {
          this.selectedBinCode = null
        }
      },

      search(val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    data () {
      return {
        search:null,
        searchModel:null,
        searchItems:[],
        isLoading:false,
        selectedBinCode:null,

        dialogScan:false,
        dialogAlaram:false,

        columns:[],
        datas:[],
        selectedItem:[],

        dates:[],

        scanner:null,
        qrbox:null,
        fps:null,

        start_menu:false,
        end_menu:false,

        start_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end_date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        alaramText:'',
      }
    },

    methods: {
      openScanner() {
        this.setIsScan()
         .then(() => {
           this.startScan()
         })
      },
      setIsScan() {
        var me = this
        return new Promise(function(resolve, reject) {
            me.dialogScan = true
            resolve(true)
        })
      },
      startScan() {
        this.scanner = new Html5Qrcode(
        "barcodeScan", { /*fps: 10, qrbox: {width: 250, height: 50}*/ }, false);
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
          .then(devices => {
            var len = devices.length;
            var device = devices[len-1].id
            this.scanner.start(device, { fps: 10, qrbox: {width: 250, height: 50} }, this.onScanSuccess);

            
        });
      },
      onScanSuccess(qrCodeMessage) {
          this.searchFn(qrCodeMessage);
          this.scanner.stop();
          this.dialogScan = false;
      },
      onScanError(errorMessage) {
          console.log(errorMessage);
      },

      searchFn(val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
          uid_company:this.$store.getters.getUidCompany,
          bin_code:val
        }
        var params = '';
        for(var key in pObj) {
          params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            this.searchItems = res.result
            if(this.searchItems!=null && this.searchItems.length>0) {
              this.searchModel = this.searchItems[0]
              this.click(this.searchItems[0])
              this.getDatas()
            }
          })
          .catch(err => {
            console.log(err)
          })
      },

      click() {
        if(this.searchModel!=null) {
          this.selectedBinCode = this.searchModel.unique_id
        }
      },

      getDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readBinRate';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        // search item condition
        if(this.selectedBinCode==null || this.selectedBinCode < 1) {
          this.dialogAlaram = true;
          this.alaramText = '위치를 검색해주세요';
          return;
        }

        obj['uid_bin'] = this.selectedBinCode

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
            const datas = res.datas;
            this.datas = datas;
          })
      },

      clear() {
        this.datas = []
      }

    }
  }
</script>